import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import LightFooter from "../Footer/LightFooter";
import UserEditPopup from "../UserEditPopup/UserEditPopup";
import { AuthContext } from "../../../providers/AuthProvider";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../Footer/Footer";

function UserSettings() {
  const [popup, setPopup] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [currentSelected, setCurrentSelected] = useState("");

  const [textToCopy, setTextToCopy] = useState("PLUS4WJKS");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);

      toast.success("Referral link copied to clipboard !", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } catch (err) {
      console.error("Unable to copy to clipboard: ", err);
    }
  };

  useEffect(() => {
    if (popup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [popup]);

  const account = [
    {
      profileImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJIwASCJpICHRbFDOQXQ2S-pmikc8vs6K2GA&usqp=CAU",
      name: "krishna",
      userName: "und band",
      email: "kkgkrishna112@gmail.com",
      mobile: 7651893713,
      state: "up",
    },
  ];

  const settings = [
    {
      title: "Class Reminder",
      settingsId: "reminder-class",
      options: [
        {
          itemKey: "email",
          value: true,
          optionId: "option1",
        },
        {
          itemKey: "push",
          value: true,
          optionId: "option2",
        },
        {
          itemKey: "push",
          value: true,
          optionId: "option3",
        },
      ],
    },
    {
      title: "Daily Digest",
      settingsId: "daily-digest",
      options: [
        {
          itemKey: "email",
          value: true,
          optionId: "option1",
        },
        {
          itemKey: "push",
          value: true,
          optionId: "option2",
        },
        {
          itemKey: "push",
          value: true,
          optionId: "option3",
        },
      ],
    },
  ];

  const getData = (uploadImageData) => {
    console.log("uploadImageData", uploadImageData);
    setImageUrl(uploadImageData);
  };

  const handleScroll = (id) => {
    const item = document.getElementById(id);

    if (item) {
      item.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickScroll = (value) => {
    // console.log("userSettings ke andar handleScroll me click button", value)
    if (value === "account") {
      handleScroll("account");
    }
    if (value === "manage") {
      handleScroll("manage");
    }
  };

  return (
    <div>
      <Toolbar />

      <div className="flex justify-center text-gray-500 my-5 ">
        <ToastContainer />

        <div className="w-[95%] md:w-[70%] tablet:w-[90%] flex flex-col md:flex-row justify-between ">
          <div className="w-full my-5 md:w-[19%]  ">
            <p className="md:text-xl font-bold text-gray-600">Settings</p>
            <ul className="text-sm md:text-base mt-5">
              <li
                className="mt-2 px-3 py-1 hover:font-semibold   cursor-pointer rounded hover:bg-gray-100"
                onClick={() => handleClickScroll("account")}
              >
                Account
              </li>
              <li
                className="mt-2 px-3 py-1 hover:font-semibold   cursor-pointer rounded hover:bg-gray-100"
                onClick={() => handleClickScroll("manage")}
              >
                Manage Updates
              </li>
              <li className="mt-2 px-3 py-1 hover:font-semibold   cursor-pointer rounded hover:bg-gray-100">
                Prior Plus Courses
              </li>
            </ul>
          </div>
          <div className="w-full my-5 md:w-[79%]">
            <div className="">
              <p className="md:text-xl text-gray-600 font-bold">
                Refer your friends
              </p>

              <div className="p-5  my-5 shadow-md rounded-md">
                <div className=" flex justify-between ">
                  <div className="">
                    <p className="text-base md:md:text-xl font-semibold  text-[#313639] mb-2">
                      Refer friends to win Amazon vouchers and Plus Subscription
                    </p>
                    <p className="text-sm">
                      For every successful referral win up to ₹2,500. Also get 1
                      month plus subscription once
                    </p>
                  </div>
                  <div className="md:flex justify-end hidden md:block">
                    <img
                      src={require("../../../assets/Dexlabz/web_refer_and_win_a_laptop_free_user.png")}
                      alt=""
                      className="w-[50%]"
                    />
                  </div>
                </div>
                <div className=" flex flex-col md:flex-row justify-between my-5 p-3 rounded-md bg-gray-50">
                  <div className=" flex flex-row md:flex-col font-semibold items-center justify-between md:justify-start">
                    <p className="text-xs">Your referral code</p>
                    <p className="text-lg text-gray-600">{textToCopy}</p>
                  </div>
                  <div className="flex mt-5 md:mt-0">
                    <CustomButton
                      lable={`Copy code`}
                      className={`bg-gray-700 text-white !rounded-md hover:-translate-y-0.5`}
                      onClick={() => copyToClipboard()}
                    />
                    <CustomButton
                      lable={`Learn more`}
                      className={`hover:bg-gray-200 text-gray-700 !rounded-md border-gray-200`}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 " id="account">
              <p className="md:text-xl font-bold text-gray-600">Account</p>
              <ul className="shadow-md my-5 p-5 rounded-md">
                <li className=" flex justify-between items-center p-2 border-b pb-3">
                  <img src={imageUrl} alt="" className="w-[40px] md:w-[50px]" />

                  <CustomButton
                    lable={`Edit profile image`}
                    className={`text-green-500 border-gray-200 hover:bg-gray-200 !text-xsm md:!text-sm`}
                    onClick={() => setPopup(true)}
                  />
                </li>
              </ul>
            </div>

            <div className="mt-10 " id="manage">
              <p className=" text-lg md:text-2xl font-bold text-gray-600">
                Manage Updates
              </p>

              <ul className=" mb-5 p-5">
                {settings.map((data, index) => (
                  <div className="" key={index}>
                    <p className="text-gray-600 text-xl py-4 font-bold">
                      {data.title}
                    </p>

                    <div className=" flex flex-col justify-between items-center p-2 border-b pb-5 my-2 shadow-md  rounded-md">
                      {data.options.map((option, index) => (
                        <SettingsItems
                          key={index}
                          itemKey={option.itemKey}
                          value={option.value}
                          title={data.title}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      {popup && (
        <UserEditPopup
          popup={popup}
          setPopup={setPopup}
          className={`animate-bottomPopup`}
          getData={getData}
        />
      )}
    </div>
  );
}

const SettingsItems = ({ value, itemKey }) => {
  const [button, setButton] = useState(value);

  return (
    <div className="flex  w-[95%] justify-between py-4 ">
      <p className="uppercase font-semibold">{itemKey}</p>

      <div className="flex border rounded-md">
        <div
          className={` py-2 px-5 !text-xsm md:!text-sm  cursor-pointer text-gray-500 font-semibold rounded-l-md ${
            button ? "bg-green-500 text-white" : "bg-white "
          }`}
          onClick={() => setButton(value)}
        >
          ON
        </div>
        <div
          className={` py-2 px-5 !text-xsm md:!text-sm  cursor-pointer text-gray-500 font-semibold rounded-r-md ${
            button ? "bg-white" : "bg-gray-400 text-white"
          }`}
          onClick={() => setButton(false)}
        >
          OFF
        </div>
      </div>
    </div>
  );
};

export default UserSettings;

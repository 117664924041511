import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiConstants } from "./ApiConstants";

export const DashBoardApi = createApi({
  reducerPath: "dashboard",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiConstants.BASE_URL,
  }),
  endpoints: (builder) => ({
    getEnrolledByUserId: builder.query({
      query: (userId) => `${ApiConstants.GET_ENROLLED_BY_USERID}/${userId}`,
    }),
    getStoreDataByActiveGoal: builder.query({
      query: (id) => `${ApiConstants.ACTIVE_GOAL_STORE}/${id}`,
    }),
    getAllSectionsByCourseUid: builder.query({
      query: (id) => `${ApiConstants.GET_ALL_SECTIONS_BY_COURSE_UID}/${id}`,
    }),
    getAllChaptersBySectionUid: builder.query({
      query: ({ id, token }) => ({
        url: `${ApiConstants.GET_ALL_CHAPTER_BY_SECTION_UID}/${id}`,
        headers: {
          "auth-token": token,
        },
      }),
    }),
    getAllLecturesByChapterUid: builder.query({
      query: ({ id, token }) => ({
        url: `${ApiConstants.GET_ALL_LECTURE_BY_CHAPTER_UID}/${id}`,
        headers: {
          "auth-token": token,
        },
      }),
    }),
    getLectureByCourseUid: builder.query({
      query: (courseUid) =>
        `${ApiConstants.GET_LECTURE_BY_COURSE_UID}/${courseUid}`,
    }),
    getLectureByLectureUid: builder.query({
      query: (lectureUid) =>
        `${ApiConstants.GET_LECTURE_BY_Lecture_UID}/${lectureUid}`,
    }),
    getQuestionByLectureUid: builder.query({
      query: (lectureUid) =>
        `${ApiConstants.GET_ALL_QUESTION_BY_LECTURE_ID}/${lectureUid}`,
    }),

    setActiveGoal: builder.query({
      query: ({ id, token }) => ({
        url: `${ApiConstants.UPDATE_ACTIVE_GOAL}/${id}`,
        headers: { "auth-token": token },
      }),
    }),
    getVideoWatchDuration: builder.query({
      query: (userId) => `${ApiConstants.VIDEO_WATCH_DURATION}/${userId}`,
    }),
    getLectureDataByCourseUid: builder.query({
      query: (courseUid) =>
        `${ApiConstants.GET_LECTURE_Data_BY_Course_UID}/${courseUid}`,
    }),

    updateActiveGoal: builder.mutation({
      query: ({ body, token }) => ({
        url: `${ApiConstants.UPDATE_ACTIVE_GOAL}`,
        method: "PUT",
        headers: {
          "auth-token": token,
        },
        body,
      }),
    }),
    createOrder: builder.mutation({
      query: ({ body, token }) => ({
        url: `${ApiConstants.CREATE_ORDER}`,
        method: "POST",
        headers: {
          "auth-token": token,
        },
        body,
      }),
    }),
    validCoupon: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstants.VALIDATE_COUPON}`,
        method: "POST",
        body,
      }),
    }),
    paymentResponce: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstants.PAYMENT_RESPONCE}`,
        method: "POST",
        body,
      }),
    }),
    saveVideoProgress: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstants.SAVE_VIDEO_PROGRESS}`,
        method: "POST",
        body,
      }),
    }),
    setUserGoal: builder.mutation({
      query: ({ body, token }) => ({
        url: `${ApiConstants.SET_USER_GOAL}`,
        method: "PUT",
        body,
        headers: {
          "auth-token": token,
        },
      }),
    }),
    generateQrCode: builder.mutation({
      query: ({ body, token }) => ({
        url: `${ApiConstants.GENERATE_QR_CODE}`,
        method: "POST",
        body,
        // headers: {
        //   "auth-token": token,
        // },
      }),
    }),
  }),
});

export const {
  useGetStoreDataByActiveGoalQuery,
  useUpdateActiveGoalMutation,
  useGetLectureByCourseUidQuery,
  useGetAllSectionsByCourseUidQuery,
  useGetAllChaptersBySectionUidQuery,
  useGetAllLecturesByChapterUidQuery,
  useGetLectureByLectureUidQuery,
  useCreateOrderMutation,
  useValidCouponMutation,
  usePaymentResponceMutation,
  useGetEnrolledByUserIdQuery,
  useSaveVideoProgressMutation,
  useGetVideoWatchDurationQuery,
  useGetQuestionByLectureUidQuery,
  useSetUserGoalMutation,
  useGenerateQrCodeMutation,
  useGetLectureDataByCourseUidQuery
} = DashBoardApi;

import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import QRCode from "react-qr-code"; // Import the QRCode component
import { FaCcVisa, FaApplePay, FaGooglePay } from "react-icons/fa";
import amazonpay from "../../../assets/PaymentApp/AmazonPay.png";
import gpay from "../../../assets/PaymentApp/googlePay.png";
import phonepe from "../../../assets/PaymentApp/PhonePe.png";
import paytem from "../../../assets/PaymentApp/Paytm.png";
import upi from "../../../assets/PaymentApp/upi.png";
import { MdCurrencyRupee } from "react-icons/md";

function PaymentQr({ bottomPopup, setShowPaymentQr, qrApiResponce }) {
  const [qrUrl, setQrUrl] = useState(""); // Initialize with an empty string

  useEffect(() => {
    if (qrApiResponce && qrApiResponce.link) {
      console.log("url", qrApiResponce.link);
      setQrUrl(qrApiResponce.link);
    } else {
      setQrUrl(""); // Reset if no valid response
    }
  }, [qrApiResponce]);

  const paymentApps = [gpay, phonepe, paytem, amazonpay, upi];

  return (
    <div className="bg-[rgba(128,128,128,.6)] w-full h-screen fixed top-0 left-0 z-50">
      <div
        className={`w-[95%] lg:w-[43%] 2xl:w-[35%] 3xl:w-[30%] 5xl:w-[22%] tablet:w-[70%] 2sm:w-[50%] bg-gray-50 h-[71vh] lg:h-[60vh] absolute right-0 left-0 top-0 bottom-0 m-auto transition-all duration-700 rounded-2xl shadow-xl ${bottomPopup}`}
      >
        {/* Header */}
        <div className="flex justify-between items-center w-full px-5">
          <p className="my-5 font-medium text-xl text-[#313639] self-start">
            Please scan QR Code
          </p>
          {/* Close Button */}
          <AiOutlineClose
            className="text-3xl cursor-pointer p-1.5 rounded-full bg-gray-400 text-white hover:bg-gray-500 hover:rotate-90 duration-200"
            onClick={() => setShowPaymentQr(false)}
          />
        </div>

        {/* QR Code Display */}
        <div className="px-5 flex justify-center items-center">
          {/* Use the QRCode component to generate a QR code */}
          {qrUrl ? ( // Check if qrUrl is set before rendering QR code
            <QRCode
              value={qrUrl} // URL to be encoded into the QR code
              size={200} // Size of the QR code
              style={{ width: "auto" }} // Ensures responsiveness
            />
          ) : (
            <p className="text-gray-700">Loading QR Code...</p> // Fallback message
          )}
        </div>
        <div className="flex justify-center items-center mt-5 text-xl font-bold">
          <MdCurrencyRupee className="" />
          <p className=" ">{qrApiResponce?.order?.amount}</p>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-5 mt-5">
          {paymentApps?.map((image, index) => (
            <img key={index} src={image} alt="" className="w-[4rem]" />
          ))}
        </div>
        <div className="px-5 mt-7">
          <p className="text-sm">
            <strong>Note -</strong> Please send the screenshot of payment
            success to <b>business@phynlabz.com</b> and the course will be
            assigned to you by quest4physics team within 2 to 4 hours.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PaymentQr;

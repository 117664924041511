import { WebConstant } from "../api/ApiConstants";

const HomePage = () => {
    return (
      <div className="flex justify-center items-center w-full h-screen bg-slate-800">
        <p className="text-white text-2xl">{WebConstant?.WEB_NAME} Home Develop</p>
      </div>
    ); 
}
 
export default HomePage;
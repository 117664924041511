import React, { useEffect, useState } from "react";

import "./SelectGoal.css";

import { BiSearchAlt } from "react-icons/bi";
import { VscNotebook } from "react-icons/vsc";
import { Link } from "react-router-dom";
import PickLanguage from "./PickLanguage/PickLanguage";

import { connect, useDispatch } from "react-redux";
import { GoalsThunk, setSubGoals } from "../../../../redux/GoalsSlice";
import Toolbar from "../Toolbar/Toolbar";
import DarkFooter from "../../Footer/DarkFooter";
import Footer from "../../Footer/Footer";

function SelectGole({ goals: goalStateArray, setCurrentGoal, subGoalList }) {
  const [showLanguageBox, setShowLanguageBox] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSelected, setCurrentSelected] = useState();
  const [currentGoalId, setCurrentGoalId] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    // getGoal();
    dispatch(GoalsThunk());
  }, []);

  useEffect(() => {
    if (showLanguageBox) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showLanguageBox]);

  // console.log("currentSelected", goal._id);
  useEffect(() => {
    if (showLanguageBox) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Enable scroll
    }
    // Cleanup on component unmount or popup close
    return () => {
      document.body.style.overflow = "";
    };
  }, [showLanguageBox]);

  return (
    <div>
      <div className="">
        <Toolbar />
      </div>
      <div className="flex flex-col justify-center mt-5">
        <div className="goal-bg flex justify-center text-3xl font-bold w-full">
          <p className="py-10 bg-[rgba(255,255,255,.6)]  text-center w-full h-full  ">
            Choose your goal
          </p>
        </div>

        <div className="flex items-center justify-center w-full  my-5">
          <div className="hidden tablet:block lg:block  w-[40%] md:flex items-center">
            <label className="relative block ">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-xl">
                <BiSearchAlt />
              </span>
              <input
                className="placeholder:italic placeholder:text-slate-400 block  w-full border border-slate-300 rounded-md py-4 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                placeholder="Search for anything..."
                type="text"
                name="search"
              />
            </label>
          </div>
        </div>

        <div className="flex md:justify-center my-10 w-[95%] self-center overflow-hidden">
          <div className="w-full lg:w-[65%] tablet:w-[95%]   flex md:justify-between flex-col md:flex-row ">
            <div className="w-full lg:w-[30%] tablet:w-[30%] md:p-5 flex flex-row md:flex-col md:h-[70vh] overflow-x-scroll md:overflow-x-hidden overflow-y-scroll pb-5">
              {goalStateArray?.map((goal, index) => (
                <Link
                  key={index}
                  to=""
                  onClick={() => {
                    setCurrentGoal(goal._id);
                    setCurrentGoalId(goal?._id);
                  }}
                  className={`my-1 mx-1   py-1 px-3 text-gray-500  hover:font-bold hover:bg-gray-50 border md:border-none rounded-md min-w-[13rem] `}
                >
                  {goal?.goalTitle}
                </Link>
              ))}
            </div>

            <div className="w-full lg:w-[65%] tablet:w-[70%]  md:px-5">
              <div className="flex flex-row flex-wrap  w-full mx-0 px-0">
                {subGoalList?.map((subgoal, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setShowLanguageBox(true);
                      setCurrentIndex(index);
                      setCurrentSelected(subgoal._id);
                    }}
                    className={`p-5 py-8 w-[150px] md:w-[200px] tablet:scale-90  flex items-start flex-col rounded-xl border text-gray-500  m-2  cursor-pointer shadow-md tablet:px-5 ${
                      currentSelected === subgoal._id
                        ? "border-2 border-orange-300"
                        : ""
                    }`}
                  >
                    <div className="text-4xl mb-5">
                      <VscNotebook />
                    </div>
                    <p className="font-bold text-sm min-w-full">
                      {subgoal.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLanguageBox && (
        <PickLanguage
          animationClass={`animate-rightPopup`}
          setShowLanguageBox={setShowLanguageBox}
          subGoalList={subGoalList}
          currentIndex={currentIndex}
          currentSelected={currentSelected}
          currentGoalId={currentGoalId}
        />
      )}
      {/* language select box */}

      <div className="">
        <Footer />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    goals: state.goalsReducer.goals,
    subGoalList: state.goalsReducer.currentSubGoal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentGoal: (id) => {
      dispatch(setSubGoals(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectGole);

import React, { useContext, useEffect, useRef, useState } from "react";

import BrandLogo from "../../Shared/BrandLogo/BrandLogo";
import defaultAvatar from "../../../../assets/Dexlabz/user-image.png";
import { BsFillPatchCheckFill } from "react-icons/bs";
import {
  BiMenu,
  BiPlus,
  BiSearchAlt,
  BiSolidGift,
  BiSolidPhoneCall,
} from "react-icons/bi";

import { GoGoal } from "react-icons/go";

import { PiNotebookLight } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";

import { Link, useLocation, useNavigate } from "react-router-dom";
import ProfilePopup from "../ProfilePopup/ProfilePopup";
import EmailConformedPopup from "../EmailConformedPopup/EmailConformedPopup";
import { AuthContext } from "../../../../providers/AuthProvider";
import MobileMenu from "../../MobileMenu/MobileMenu";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  useGetEnrolledByUserIdQuery,
  useUpdateActiveGoalMutation,
} from "../../../../api/ReduxToolkit";
import {
  UserSelectGoalThunk,
  setUserGoalInfo,
} from "../../../../redux/UserSelectGoalSlice";
import AppStateReducer, {
  showGlobalProgressIndicator,
} from "../../../../redux/AppStateReducer";
import { optimizeImage } from "../../../../api/ApiConstants";
import { FaRegUserCircle } from "react-icons/fa";
import WebLogo from "../../../WebLogo/WebLogo";

function Toolbar({
  handleScroll,
  userSelectGoal,
  activeGoal,
  updateActiveGoalTitle,
  setIsDashBoardLogin,
}) {
  const navigate = useNavigate();
  const [showSelectGoalDropdown, setShowSelectGoalDropdown] = useState(false);
  const [iconRotate, setIconRotate] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [currentId, setCurrentId] = useState(activeGoal?._id);
  const [currentGolaTitle, setCurrentGolaTitle] = useState();
  const { userInfo, accessToken } = useContext(AuthContext);
  const [updateActiveGoal] = useUpdateActiveGoalMutation();

  const location = useLocation();

  let menuRef = useRef();
  const userAvatarUrl = userInfo?.avatar?.url;

  const handleImageError = (event) => {
    event.target.src = defaultAvatar;
  };

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e?.target)) {
        setShowProfilePopup(false);
        setShowSelectGoalDropdown(false);
        setIconRotate(false);
        // console.log(menuRef.current);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleDropdown = () => {
    setShowSelectGoalDropdown(!showSelectGoalDropdown);
    setIconRotate(!iconRotate);
  };

  // console.log("toolbar ke me active goal = ", activeGoal);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserSelectGoalThunk(accessToken));
  }, []);

  const [bottomMenu, setBottomMenu] = useState([
    // {
    //   isActive: true,
    //   link: "",
    //   item: "Get started",
    // },
    // {
    //   isActive: false,
    //   link: "",
    //   item: "Educators",
    // },
    // {
    //   isActive: false,
    //   link: "",
    //   item: "Batch",
    // },
    {
      isActive: false,
      link: "/store",
      item: "Store",
    },
    {
      isActive: false,
      link: "/classroom",
      item: "Classroom",
    },
    {
      isActive: false,
      link: "/success-stories",
      item: "Success Stories",
    },
    // {
    //   isActive: false,
    //   link: "",
    //   item: "About Exam",
    // },
  ]);

  const handleBottomMenuCLick = ({ item }) => {
    // console.log(item);

    if (item === "Batch") {
      handleScroll("SubscribeAdvertisment");
    }
    if (item === "Get started") {
      handleScroll("homeSlider");
    }
    if (item === "Educators") {
      handleScroll("educator");
    }
    if (item === "About Exam") {
      handleScroll("CentersCase");
    }

    const updatedMenu = bottomMenu.map((menu) => {
      if (menu.item === item) {
        return { ...menu, isActive: true };
      } else {
        return { ...menu, isActive: false };
      }
    });
    setBottomMenu(updatedMenu);
  };

  const handleMarkActiveCurrentGoal = (id, goalTitle) => {
    // console.log("data aa rha ki nhi ");
    dispatch(showGlobalProgressIndicator(true));

    updateActiveGoal({
      body: { newGoal: id },
      token: accessToken,
    }).then(() => {
      setCurrentId(id);
      setCurrentGolaTitle(goalTitle);
      dispatch(UserSelectGoalThunk(accessToken));
      updateActiveGoalTitle({ id: currentId, goalTitle: currentGolaTitle });

      dispatch(showGlobalProgressIndicator(false));
    });
  };

  return (
    <div>
      <div className="flex justify-center flex-col  items-center border-b  ">
        <div
          className="w-[95%]  lg:w-[70%] tablet:w-[90%]  flex justify-between   tablet:py-3 items-center "
          ref={menuRef}
        >
          <div className="flex flex-row items-center gap-2 lg:gap-5">
            <div className=" cursor-pointer" onClick={() => navigate("/")}>
              <WebLogo className={`w-[10rem] `} />
            </div>

            {/* dropdown code */}

            <ul className="">
              <li
                className={`flex items-center   px-3 py-2 flex-col lg:relative hover:bg-gray-100-200 cursor-pointer  rounded-md   font-semibold  text-gray-500 border ${
                  showSelectGoalDropdown ? "bg-gray-100" : ""
                }`}
                onClick={handleDropdown}
              >
                <p className="flex items-center text-xs">
                  {activeGoal?.goalTitle || "Choose Goals"}
                  <IoIosArrowDown
                    className={`ms-3   transition-all duration-500 ${
                      iconRotate ? "rotate-180" : ""
                    }`}
                  />
                </p>

                {showSelectGoalDropdown && (
                  <ul className="absolute top-12 left-0 right-0 mx-auto flex flex-col items-start shadow-md w-[15rem] 2xl:w-[20rem] py-3 px-4 rounded-lg bg-white z-40 animate-goalPopup ">
                    <div className=" flex justify-between w-full items-center pe-3 mb-3 border-b py-3 text-gray-500">
                      <p className="font-bold text-base lg:text-lg">My goals</p>
                      <Link
                        to=""
                        className="font-bold text-xs border-b border-dotted border-gray-500"
                      >
                        Edit
                      </Link>
                    </div>

                    {userSelectGoal?.map((data, index) => {
                      return (
                        <li
                          key={index}
                          className=" w-full my-1  text-sm  flex items-center border-b py-3 font-semibold"
                          onClick={() =>
                            handleMarkActiveCurrentGoal(
                              data?._id,
                              data?.goalTitle
                            )
                          }
                        >
                          <GoGoal className=" text-gray-500 text-3xl lg:text-3xl me-5" />
                          <p
                            className={` flex items-center justify-between w-full ${
                              data?._id === activeGoal?._id
                                ? "font-extrabold"
                                : "font-medium"
                            }`}
                          >
                            {data?.goalTitle}
                            {data?._id === activeGoal?._id && (
                              <BsFillPatchCheckFill className="text-green-600 text-xl" />
                            )}
                          </p>
                        </li>
                      );
                    })}

                    <div
                      className=" flex  w-full items-center pe-3 mt-3  text-sm  py-2"
                      onClick={() => navigate("/goal")}
                    >
                      <BiPlus className=" me-3 text-2xl" />
                      <p className="">Add another goal</p>
                    </div>
                  </ul>
                )}
              </li>
            </ul>

            {/* dropdown code */}
          </div>

          {/* <div className="hidden tablet:hidden lg:block w-[40%] md:flex items-center">
            <label className="relative block">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <BiSearchAlt />
              </span>
              <input
                className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                placeholder="Search for anything..."
                type="text"
                name="search"
                autoComplete="off"
              />
            </label>
          </div> */}

          <div className="lg:flex flex-row items-center me-3 tablet:hidden hidden lg:block relative justify-end">
            <div className="hidden lg:block lg:flex flex-row items-center mx-5 text-gray-500">
              <BiSolidPhoneCall className="text-2xl 2xl:text-3xl mx-4" />
              <div className="">
                <p className="text-xs   text-gray-500">Talk to our experts</p>
                <Link to="">
                  <b className="font-mono lg:text-xs 2xl:text-sm hover:text-red-600">
                    +91 7292986896
                  </b>
                </Link>
              </div>
            </div>

            {/* <Link to="" className="">
              <p
                className=" mx-3  border border-gray-
                            300 p-1 rounded-full 2xl:mx-5 "
              >
                <img
                  src="https://static.uacdn.net/production/_next/static/images/giftHomePage.svg?q=75&auto=format%2Ccompress&w=50"
                  alt=""
                />
              </p>
            </Link> */}

            <div className="w-[2rem]  2xl:w-[2.5rem]  h-[2.5rem] cursor-pointer flex items-center justify-center">
              {userInfo?.avatar?.url ? (
                <img
                  src={userAvatarUrl || defaultAvatar}
                  alt="User Avatar"
                  className=" rounded-full border cursor-pointer"
                  onError={handleImageError}
                  onClick={() => setShowProfilePopup(!showProfilePopup)}
                />
              ) : (
                // <img
                //   src={defaultAvatar}
                //   alt="User Avatar"
                //   className=" rounded-full border"
                //   onClick={() => setShowProfilePopup(!showProfilePopup)}
                // />
                <FaRegUserCircle
                  className="text-3xl cursor-pointer"
                  onClick={() => setShowProfilePopup(!showProfilePopup)}
                />
              )}
            </div>

            {showProfilePopup && <ProfilePopup />}
          </div>

          <div
            className="lg:me-5 text-4xl md:hidden tablet:block"
            onClick={() => setShowMenu(!showMenu)}
          >
            <BiMenu />
          </div>
        </div>

        {/* menu code  */}

        {location.pathname === "/" && (
          <div className="w-[90%] mx-2 lg:w-[70%] 2xl:w-[70%] flex justify-start items-center  my-2 hidden lg:block ">
            <ul className="flex overflow-x-scroll md:overflow-hidden ">
              {bottomMenu.map((data, index) => (
                <li
                  className=" mx-2 font-bold text-gray-500 hover:text-gray-600 text-sm   "
                  key={index}
                >
                  <Link
                    onClick={() => handleBottomMenuCLick(data)}
                    to={data.link}
                    className={`${
                      data.isActive
                        ? "border-b-2 border-gray-700 text-gray-700"
                        : ""
                    }`}
                  >
                    {data.item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* menu code  */}

        {/* mobile ke liye  menu code  */}

        <MobileMenu
          setShowMenu={setShowMenu}
          showMenu={showMenu}
          bottomMenu={bottomMenu}
        />

        {/* mobile ke liye  menu code  */}
      </div>

      {/* {!userInfo?.isEmailVerified && ( */}
      <EmailConformedPopup userInfo={userInfo} />
      {/* )} */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userSelectGoal: state?.userSelectGoalReducer?.userGoalInfo?.goals,
    activeGoal: state?.userSelectGoalReducer?.userGoalInfo?.activeGoal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveGoalTitle: (data) => {
      dispatch(setUserGoalInfo(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);

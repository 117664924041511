import React, { useState } from "react";
import loginImg from "../../../assets/Dexlabz/hero-bg.svg";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import OTPInput from "react-otp-input";
import axios, { HttpStatusCode } from "axios";
import { ApiConstants, WebConstant } from "../../../api/ApiConstants";
import Registration from "../Registration/Registration";

function JoinFree({ setShowJoinFreePopup, setShowLoginPopup, className }) {
  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSignUpForm, setIsSignUpForm] = useState(false);

  function ValidateEmail(mail) {
    if (
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const handleGenerateOtp = async () => {
    setError(false); // Reset error state on new request
    if (!email) {
      setError(true);
      setErrorMessage("Email is required!");
      return;
    }

    if (!ValidateEmail(email)) {
      setError(true);
      setErrorMessage("Entered email is incorrect!");
      return;
    }

    const bodyObject = {
      email: email,
    };

    try {
      const { status } = await axios.post(
        ApiConstants.BASE_URL + ApiConstants.REGISTRATION_GENERATE_OTP_URL,
        bodyObject
      );

      if (status === HttpStatusCode.Ok) {
        setIsOtpRequested(true);
        setSuccess(true);
        setSuccessMessage(`We have sent an OTP to ${email}`);
        setError(false);
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.message || "Failed to generate OTP."
        );
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
      setError(true);
      setSuccess(false);
    }
  };

  const handleVerifyOtp = async () => {
    setError(false); // Reset error state on new request
    if (otp.length < 6) {
      setError(true);
      setErrorMessage("OTP must be 6 digits long.");
      return;
    }

    const bodyObject = {
      email: email,
      otp: otp,
    };

    try {
      const { status } = await axios.post(
        ApiConstants.BASE_URL + ApiConstants.REGISTRATION_VERIFY_OTP_URL,
        bodyObject
      );

      if (status === HttpStatusCode.Ok) {
        setIsSignUpForm(true);
        setErrorMessage("");
        setError(false);
      } else {
        setError(true);
        setErrorMessage("Incorrect OTP.");
      }
    } catch (error) {
      if (error.response) {
        // console.log(error);
        setErrorMessage(error?.response?.data?.msg || "Failed to verify OTP.");
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
      setError(true);
    }
  };

  return (
    <div>
      <div className="bg-[rgba(128,128,128,.8)] w-full h-screen fixed top-0 left-0 z-50 rounded-md">
        <div
          className={`bg-white h-[100vh] w-full lg:w-full tablet:w-[50%] 2sm:w-[60%] absolute left-0 right-0 mx-auto top-0 bottom-0 my-auto flex items-center justify-between flex-col md:flex-row  md:px-0 ${className}`}
        >
          <div className="hidden lg:block md:w-[60%]">
            <div className="bg-blue-100 p-5 rounded-s-md h-[18rem] md:h-[100vh] flex flex-col-reverse items-center md:block tablet:hidden relative">
              <div className="px-5 text-gray-500 pt-32">
                <p className="font-bold text-gray-600 text-2xl">
                  Over 10 crore learners trust us for their preparation
                </p>
                <ul className="list-disc ps-5 mt-5 text-lg">
                  <li className="my-5">
                    Learn from 2,000+ India’s top educators
                  </li>
                  <li className="my-5">
                    Access 3,00,000+ live classes, test series, courses and more
                    for free
                  </li>
                </ul>
              </div>
              <img
                src={loginImg}
                className="scale-90 w-[40rem] absolute bottom-10 left-0 right-0 mx-auto"
                alt=""
              />
            </div>
          </div>

          <div className="w-full md:w-[40%] tablet:w-full px-5 py-10 md:p-10 lg:px-20 flex justify-start flex-col items-start font-poppins">
            <p className="text-3xl tablet:text-3xl text-center lg:text-4xl font-bold font-poppins">
              Join Quest4Physics
            </p>
            <p className="text-base tablet:text-xl tablet:ms-0 tablet:ps-0 text-center 2xl:text-lg mt-2">
              Already have an account?{" "}
              <Link
                to=""
                className="text-red-500 font-bold"
                onClick={() => {
                  setShowJoinFreePopup(false);
                  setShowLoginPopup(true);
                }}
              >
                Log in
              </Link>
            </p>

            <div className="my-5 w-full">
              {error && (
                <p className="bg-red-200 text-red-800 p-2 my-3 rounded">
                  {errorMessage}
                </p>
              )}
              {success && (
                <p className="bg-green-100 text-green-800 p-2 text-sm my-3 rounded">
                  {successMessage}
                </p>
              )}

              {!isOtpRequested && (
                <div className="sm:col-span-3">
                  <div className="my-2">
                    <input
                      type="email"
                      value={email}
                      className="block w-full rounded-md border-0 py-4 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:leading-6 text-lg md:text-xl"
                      placeholder="Email address"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setError(false);
                      }}
                    />
                  </div>
                  <p className="my-2 text-sm lg:text-base text-gray-600">
                    We’ll send an OTP for verification
                  </p>
                </div>
              )}

              {!isSignUpForm && isOtpRequested && (
                <div className="flex justify-center my-5">
                  <OTPInput
                    value={otp}
                    onChange={(otpValue) => {
                      setOtp(otpValue);
                      setSuccess(false); // Reset success when typing OTP
                    }}
                    inputStyle={{ width: "2.5rem" }}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className="border border-black rounded-md px-2 mx-1 h-[3rem]"
                      />
                    )}
                  />
                </div>
              )}

              {isSignUpForm && (
                <Registration
                  setEmail={setEmail}
                  email={email}
                  setShowLoginPopup={setShowLoginPopup}
                  setShowJoinFreePopup={setShowJoinFreePopup}
                  setIsOtpRequested={setIsOtpRequested}
                />
              )}

              {!isOtpRequested && (
                <CustomButton
                  lable={"Request OTP"}
                  className="w-full !mx-0 my-4 !text-lg !py-4 bg-gray-700 text-white border-transparent hover:bg-gray-800"
                  onClick={handleGenerateOtp}
                />
              )}

              {isOtpRequested && !isSignUpForm && (
                <CustomButton
                  lable={"Verify OTP"}
                  className="w-full !mx-0 my-4 !text-lg !py-4 bg-gray-700 text-white border-transparent hover:bg-gray-800"
                  onClick={handleVerifyOtp}
                />
              )}

              {isOtpRequested && !isSignUpForm && (
                <p className="text-xs text-center 2xl:text-sm mt-2 font-bold underline decoration-dashed underline-offset-8 tracking-widest">
                  <Link to="" className="text-bold">
                    Continue with email
                  </Link>
                </p>
              )}

              <p className="text-xsm text-center 2xl:text-sm mt-2 absolute bottom-5 self-center w-full">
                Having trouble? <br />
                Write us on{" "}
                <Link to="" className="text-bold font-normal">
                  help@{WebConstant?.WEB_NAME}.com
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinFree;

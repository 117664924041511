import React, { useState } from "react";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import LightFooter from "../Footer/LightFooter";

import referImage from "../../../assets/Dexlabz/referImage.png";
import CustomButton from "../Shared/CustomButton/CustomButton";
import copy_icon from "../../../assets/Dexlabz/copy_icon.svg";
import { FaShare } from "react-icons/fa";

import {
	MdOutlineArrowForwardIos,
	MdOutlineCurrencyRupee,
} from "react-icons/md";
import referDetails from "../../../assets/Dexlabz/referralDetails.svg";
import faq from "../../../assets/Dexlabz/faq.svg";
import tnc from "../../../assets/Dexlabz/terms.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer/Footer";
// import { enqueueSnackbar } from "notistack";

function Refer() {
	const navigate = useNavigate();

	const [textToCopy, setTextToCopy] = useState("PLUS4WJKS");

	const copyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(textToCopy);

			toast.success("Referral link copied to clipboard !", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 3000,
			});
		} catch (err) {
			console.error("Unable to copy to clipboard: ", err);
		}
	};

	return (
		<div className="">
			<div className="">
				<Toolbar />
			</div>

			<div className="flex justify-center flex-col  items-center  bg-[#FFF5E5] ">
				<div className="w-[95%]  lg:w-[70%] tablet:w-[90%]  flex justify-between   tablet:py-3 items-start my-10  ">
					<ToastContainer />
					<div className="w-full md:w-[50%]">
						<p className="text-3xl lg:text-4xl tablet:text-3xl text-[#522C2C] font-bold ">
							Refer friends, win <br />
							exciting rewards
						</p>
						<p className="text-sm lg:text-base tablet:text-sm text-[#522C2C] font-semibold mt-10 my-5">
							For every successful referral you can win exciting rewards
						</p>

						<div className="w-full flex   justify-between items-center	 md:my-5 p-2 md:p-3 rounded-md bg-white  fixed bottom-0 left-0 z-10 md:static  border-t md:border-none">
							<div className=" flex flex-col font-semibold items-center justify-center">
								<p className="text-xs">Your referral code</p>
								<p className="text-lg text-[#313639] md:mt-1 font-bold font-poppins">
									{textToCopy}
								</p>
							</div>
							<div className="flex  ">
								<CustomButton
									lable={`Copy `}
									hasIcon={true}
									className={`hidden md:block hover:bg-gray-50 text-gray-700 !rounded-md border-gray-300 text-lg !font-bold hover:!translate-y-0 !px-3	`}
									onClick={() => copyToClipboard()}
								>
									<img src={copy_icon} alt="" />
								</CustomButton>

								<CustomButton
									lable={<img src={copy_icon} alt="" />}
									className={`block md:hidden `}
									onClick={() => copyToClipboard()}
								/>

								<CustomButton
									lable={`Share `}
									hasIcon={true}
									className={`hidden md:block bg-gray-700 text-white !rounded-md  !font-bold hover:!translate-y-0 !px-3	`}
								>
									<FaShare />
								</CustomButton>
								<CustomButton
									lable={<FaShare className="text-xl" />}
									className="block md:hidden bg-gray-700 text-white border-gray-700"
								/>
							</div>
						</div>
					</div>
					<div className="w-[40%] p-5 hidden md:block">
						<img src={referImage} alt="" />
					</div>
				</div>
			</div>

			<div className="flex justify-center flex-col  items-center  bg-white ">
				<div className="w-[95%]  lg:w-[70%] tablet:w-[90%]  flex flex-col md:flex-row justify-between  tablet:py-3 items-start my-10 lg:px-20 ">
					<div className="w-full md:w-[50%]">
						<div className="">
							<p className="text-xl font-semibold text-[#313639] ">
								Monthly hall of fame
							</p>
							<p className="flex items-center">
								<img
									src={require("../../../assets/Dexlabz/scrollProfile.gif")}
									alt=""
									className="w-[8rem] -translate-x-10"
								/>
								<span className=" -translate-x-16 text-xs text-gray-400 ">
									₹1.5L awarded to 153 learners this month
								</span>
							</p>
						</div>

						<div className="border p-5 my-5 rounded-md w-full">
							{/* card code  */}

							<div className="flex justify-between items-center ">
								<div className="flex justify-start items-start">
									<img
										src={require("../../../assets/Dexlabz/IMG_20220322_132314301.jpg")}
										// src={require("../../../assets/Dexlabz/user_awtar.png")}
										alt=""
										className="w-[3rem]  rounded-full "
									/>
									<p className="ps-3 flex flex-col ">
										<span className="font-semibold text-[#313639] text-lg">
											name
										</span>
										<span className="text-gray-400 -translate-y-1 text-xs">
											1 referrals
										</span>
									</p>
								</div>
								<div className="flex items-center font-poppins bg-orange-50 text-orange-600 font-medium text-xs">
									<MdOutlineCurrencyRupee /> <span>5000</span>
								</div>
							</div>
							{/* card code  */}
						</div>
					</div>

					<div className="w-full md:w-[30%]">
						<div className="">
							<p className="text-xl font-bold text-[#313639] ">
								More on rewards
							</p>
							<div className="border p-4 my-3 rounded-md text-[#313639]">
								<div className="flex  justify-between items-center  cursor-pointer my-3">
									<div className="flex items-center">
										<img src={referDetails} alt="" />
										<p className="ms-2 font-medium ">Reward details</p>
									</div>
									<MdOutlineArrowForwardIos />
								</div>
								<div
									className="flex  justify-between items-center  cursor-pointer my-3"
									onClick={() => navigate("/terms-conditions")}
								>
									<div className="flex items-center">
										<img src={tnc} alt="" />
										<p className="ms-2 font-medium ">Terms and conditions</p>
									</div>
									<MdOutlineArrowForwardIos />
								</div>
								<div
									className="flex  justify-between items-center  cursor-pointer my-3"
									onClick={() => navigate("/faq")}
								>
									<div className="flex items-center">
										<img src={faq} alt="" />
										<p className="ms-2 font-medium ">
											Frequently asked questions
										</p>
									</div>
									<MdOutlineArrowForwardIos />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="">
				<Footer />
			</div>
		</div>
	);
}

export default Refer;

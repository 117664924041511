import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import CustomButton from "../Shared/CustomButton/CustomButton";
import DoubtEditer from "./DoubtEditer";

function AddMyDoubtForm({ animationClass, setShowAddDoubtPopup }) {
  const [myDoubtDescription, setMyDoubtDescription] = useState("");

  return (
    <div className="  top-0 left-0 w-screen h-screen bg-[rgba(128,128,128,.8)] flex justify-start items-center  z-50 cursor-default fixed ">
      <div
        className={`w-[95%] lg:w-[30%] tablet:w-[50%] bg-white   min-h-full md:h-screen relative transition-all duration-700 p-5 ${animationClass}`}
      >
        <AiOutlineClose
          className="absolute text-3xl right-3 top-3 md:right-5 md:top-2 cursor-pointer  p-1.5 rounded-full bg-gray-400  hover:scale-105 duration-300 text-white hover:rotate-90"
          onClick={() => setShowAddDoubtPopup(false)}
        />
        <div className="flex flex-col gap-5 mt-10">
          <DoubtEditer
            setMyDoubtDescription={setMyDoubtDescription}
            label={"My Doubt"}
          />
          <CustomButton
            lable={`Send`}
            className={`!mx-0 w-[5rem] bg-green-600 hover:bg-green-600/90 text-white border-none`}
          />
        </div>
      </div>
    </div>
  );
}

export default AddMyDoubtForm;

import React from "react";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import DarkFooter from "../Footer/DarkFooter";

import { BsCheck2Square } from "react-icons/bs";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { BiPhoneCall } from "react-icons/bi";
import Footer from "../Footer/Footer";

function SubscriptionPlan() {
	const benefits = [
		{
			name: "Access to curated test series",
			plus: <BsCheck2Square className="text-[#5F8AAC] text-2xl" />,
			iconic: <BsCheck2Square className="text-[#EF953E] text-2xl" />,
			center: <BsCheck2Square className="text-gray-600 text-2xl" />,
		},
		{
			name: "Daily Practice QuestionsAccess to curated test series",
			plus: <BsCheck2Square className="text-[#5F8AAC] text-2xl" />,
			iconic: <BsCheck2Square className="text-[#EF953E] text-2xl" />,
			center: <BsCheck2Square className="text-gray-600 text-2xl" />,
		},
		{
			name: "India's best educators",
			plus: <BsCheck2Square className="text-[#5F8AAC] text-2xl" />,
			iconic: <BsCheck2Square className="text-[#EF953E] text-2xl" />,
			center: <BsCheck2Square className="text-gray-600 text-2xl" />,
		},
		{
			name: "Interactive live classes",
			plus: <BsCheck2Square className="text-[#5F8AAC] text-2xl" />,
			iconic: <BsCheck2Square className="text-[#EF953E] text-2xl" />,
			center: <BsCheck2Square className="text-gray-600 text-2xl" />,
		},
		{
			name: "Interactive live classes",
			plus: <BsCheck2Square className="text-[#5F8AAC] text-2xl" />,
			iconic: <BsCheck2Square className="text-[#EF953E] text-2xl" />,
			center: <BsCheck2Square className="text-gray-600 text-2xl" />,
		},
		{
			name: "Structured courses & PDFs",
			plus: <BsCheck2Square className="text-[#5F8AAC] text-2xl" />,
			iconic: <BsCheck2Square className="text-[#EF953E] text-2xl" />,
			center: <BsCheck2Square className="text-gray-600 text-2xl" />,
		},
		{
			name: "Live tests & quizzes",
			plus: <BsCheck2Square className="text-[#5F8AAC] text-2xl" />,
			iconic: <BsCheck2Square className="text-[#EF953E] text-2xl" />,
			center: <BsCheck2Square className="text-gray-600 text-2xl" />,
		},
		{
			name: "1:1 Live mentorship",
			plus: <BsCheck2Square className="text-[#5F8AAC] text-2xl" />,
			iconic: <BsCheck2Square className="text-[#EF953E] text-2xl" />,
			center: <BsCheck2Square className="text-gray-600 text-2xl" />,
		},
		{
			name: "Customised Doubt Solving",
			plus: <BsCheck2Square className="text-[#5F8AAC] text-2xl" />,
			iconic: <BsCheck2Square className="text-[#EF953E] text-2xl" />,
			center: <BsCheck2Square className="text-gray-600 text-2xl" />,
		},
		{
			name: "Physical Notes**",
			plus: <BsCheck2Square className="text-[#5F8AAC] text-2xl" />,
			iconic: <BsCheck2Square className="text-[#EF953E] text-2xl" />,
			center: <BsCheck2Square className="text-gray-600 text-2xl" />,
		},
	];

	return (
		<div>
			<Toolbar />

			<div className="flex justify-center my-5">
				<div className="w-[95%] tablet:w-[80%] lg:w-[70%]">
					<div className="border-b">
						<p className="text-3xl font-bold w-[50%]">
							Get subscription & start your GATE & ESE - Civil preparation today
						</p>
						<p className="my-5 ">
							{" "}
							Over{" "}
							<span className="text-green-500 font-semibold">
								8,00,000
							</span>{" "}
							learners trust us for their preparation
						</p>
					</div>

					<div className=" ">
						<p className="text-2xl font-bold my-5">Subscription plans</p>

						<div
							className="flex justify-between
            items-start mx-5"
						>
							<div className="w-[40%]">
								<div className="">
									<p className="text-lg font-bold my-3">Benefits</p>
								</div>
								<div className="my-2">
									<ul className="">
										{benefits.map((benefits) => (
											<li className=" py-1">{benefits.name}</li>
										))}
									</ul>
								</div>
							</div>

							<div className="w-[20%] flex justify-center flex-col">
								<div className="self-center">
									<p className="text-lg font-bold my-3">Plus</p>
								</div>
								<div className="my-2 self-center flex flex-col  items-center">
									<ul className="">
										{benefits.map((benefits) => (
											<li className=" py-1">{benefits.plus}</li>
										))}
									</ul>
									<div className="text-[#5F8AAC] flex flex-col items-center my-5 font-semibold">
										<p className=" text-gray-800">Starts from</p>
										<p className=" my-3">₹ 3,125 / month</p>
										<CustomButton
											lable={"Get Plus"}
											className={`border-none bg-[#5F8AAC] text-white`}
										/>
									</div>
								</div>
							</div>

							<div className="w-[20%] flex justify-center flex-col">
								<div className="self-center">
									<p className="text-lg font-bold my-3">Iconic</p>
								</div>
								<div className="my-2 self-center flex flex-col items-center">
									<ul className="">
										{benefits.map((benefits) => (
											<li className=" py-1">{benefits.iconic}</li>
										))}
									</ul>
									<div className="text-[#EF953E] flex flex-col items-center my-5 font-semibold">
										<p className=" text-gray-800">Starts from</p>
										<p className=" my-3">₹ 3,667 / month</p>
										<CustomButton
											lable={"Get Iconic"}
											className={`border-none bg-[#EF953E] text-white`}
										/>
									</div>
								</div>
							</div>

							<div className="w-[20%] flex justify-center flex-col">
								<div className="self-center">
									<p className="text-lg font-bold my-3">Centre</p>
								</div>
								<div className="my-2 self-center flex flex-col items-center">
									<ul className="">
										{benefits.map((benefits) => (
											<li className=" py-1">{benefits.center}</li>
										))}
									</ul>
									<div className="text-gray-600 flex flex-col items-center my-5 font-semibold">
										<p className=" text-gray-800">Starts from</p>
										<p className=" my-3">Kanpur</p>
										<CustomButton
											lable={"Checkout Center"}
											className={`border-none bg-[#3C4852] text-white`}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="flex justify-between mt-10">
						<div className="">
							<p className="text-xl font-bold">
								Have questions about the subscriptions ?
							</p>
							<p className=" my-3">Our expert can answer all your questions</p>
							<div className="flex justify-between">
								<CustomButton
									lable={`Get a call form us`}
									className={`!mx-0 `}
								/>
								<p className="">
									<span className="text-xs">OR CALL US</span>
									<span className="flex items-center font-serif">
										<BiPhoneCall className="me-3 " /> +91 9876543210
									</span>
								</p>
							</div>
						</div>
						<div className="">
							<img
								src={require("../../../assets/Dexlabz/TTU_illustration.png")}
								alt=""
								className="w-[50%]"
							/>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default SubscriptionPlan;

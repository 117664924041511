import React, { useState } from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";
import DoubtEditer from "./DoubtEditer";
import { AiOutlineClose } from "react-icons/ai";
import { CiCirclePlus } from "react-icons/ci";
import AddMyDoubtForm from "./AddMyDoubtForm";

function DoubtBox({ setShowDoubtPopup, animationClass }) {
  const [showMoreDobutBox, setShowMoreDobutBox] = useState("my");
  const [showAddDoubtPopup, setShowAddDoubtPopup] = useState(false);
  const [myDoubtTitle, setMyDoubtTitle] = useState("");

  // Create an array of 20 items
  const doubtArray = Array.from({ length: 20 }, (_, index) => ({
    name: `John Doe ${index + 1}`, // Example name
    title: `Doubt Title ${index + 1}`, // Example title
    description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero nemo iusto eligendi voluptatibus unde, assumenda sunt ea explicabo nam esse. Lorem ipsum dolor sit amet consectetur adipisicing elit. ${
      index + 1
    }`, // Example description
  }));

  return (
    <div className="  top-0 left-0 w-screen h-screen bg-[rgba(128,128,128,.8)] flex justify-end items-center  z-50 cursor-default fixed ">
      <div
        className={`w-[95%] lg:w-[30%] tablet:w-[50%] bg-white   min-h-full md:h-screen relative transition-all duration-700 p-5 ${animationClass}`}
      >
        <AiOutlineClose
          className="absolute text-3xl right-3 top-3 md:right-5 md:top-2 cursor-pointer  p-1.5 rounded-full bg-gray-400  hover:scale-105 duration-300 text-white hover:rotate-90"
          onClick={() => setShowDoubtPopup(false)}
        />
        <div className="flex flex-col mt-3">
          {/* Toggle between "My Doubts" and "All Doubts" */}
          <div className="flex flex-row my-5 self-start border rounded-full ">
            <p
              className={`!py-1 !px-5 mx-auto rounded-full cursor-pointer ${
                showMoreDobutBox === "my"
                  ? "font-medium bg-green-600 text-white"
                  : ""
              }`}
              onClick={() => setShowMoreDobutBox("my")}
            >
              My Doubts
            </p>
            <p
              className={`!py-1 !px-5 mx-auto rounded-full cursor-pointer ${
                showMoreDobutBox === "all"
                  ? "font-medium bg-green-600 text-white"
                  : ""
              }`}
              onClick={() => setShowMoreDobutBox("all")}
            >
              All Doubts
            </p>
          </div>

          <div className="h-full">
            {showMoreDobutBox === "my" && (
              <div className="flex flex-col gap-5">
                <CustomButton
                  lable={`Add Doubt `}
                  className={`!text-xs !me-0 bg-green-600 hover:bg-green-600/90  text-white border-none absolute top-2 left-0`}
                  onClick={() => setShowAddDoubtPopup(true)}
                />
                <div className="h-[80vh] overflow-y-scroll">
                  {doubtArray.map((doubt, index) => (
                    <div
                      key={index}
                      className="flex flex-col gap-2 shadow-no-border-shadow-3 p-2 rounded-lg"
                    >
                      <p className="text-xsm text-orange-500 font-bold">
                        {doubt.name}
                      </p>
                      <p className="line-clamp-1 text-sm font-bold">
                        {doubt.title}
                      </p>
                      <p className="line-clamp-3 text-xs">
                        {doubt.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {showMoreDobutBox === "all" && (
              <div className="flex flex-col gap-2 shadow-no-border-shadow-3 p-2 rounded-lg overflow-y-auto h-[80vh]">
                {/* Map through the doubtArray and display each doubt */}
                {doubtArray.map((doubt, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-2 shadow-no-border-shadow-3 p-2 rounded-lg"
                  >
                    <p className="text-xsm text-orange-500 font-bold">
                      {doubt.name}
                    </p>
                    <p className="line-clamp-1 text-sm font-bold">
                      {doubt.title}
                    </p>
                    <p className="line-clamp-3 text-xs">{doubt.description}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {showAddDoubtPopup && (
        <AddMyDoubtForm
          setShowAddDoubtPopup={setShowAddDoubtPopup}
          animationClass={`animate-leftPopup`}
        />
      )}
    </div>
  );
}

export default DoubtBox;

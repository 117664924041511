import React, { useContext, useEffect } from "react";
import { optimizeImage } from "../../../api/ApiConstants";
import { useGetVideoWatchDurationQuery } from "../../../api/ReduxToolkit";
import { AuthContext } from "../../../providers/AuthProvider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonGroup from "../Shared/ButtonGroup/ButtonGroup";
import ResumeLearningCard from "./ResumeLearningCard";
import { useNavigate } from "react-router-dom";

function ResumeLearning() {
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  const { data: videoData } = useGetVideoWatchDurationQuery(userInfo?.uid);

  // useEffect(() => {
  // 	console.log("video = ", videoData?.lastWatchedCourse?.[0]?.data);
  // }, [videoData]);

  //   console.log("Resume LEarning video", videoData);
  const handleCardClick = (videoData) => {
    navigate("/player", {
      state: {
        courseUid: videoData?.data?.courseId?.courseUid,
        courseName: videoData?.data?.courseId?.courseName,
        courseId: videoData?.data?.courseId?._id,
        sectionName: videoData?.data?.sectionId?.sectionName,
        sectionUid: videoData?.data?.sectionId?.sectionUid,
        sectionId: videoData?.data?.sectionId?._id,
        lectureUid: videoData?.data?.lectureId?.lectureUid,
        chapterId: videoData?.data?.chapterId,
        duration: videoData?.data?.duration,
        totalVideoDuration: videoData?.data?.totalVideoDuration,
      },
    });
  };

  return (
    <div className="">
      <div className="text-xl mb-3">
        Resume <strong>Learning</strong>
      </div>
      <div
        className="w-full lg:w-[90%] "
        // onClick={() => setShowSectionBox(!showSectionBox)}
      >
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          autoPlaySpeed={3000}
          centerMode={false}
          className="w-full self-center z-0 "
          containerClass="container-with-dots "
          dotListClass={`text-xl`}
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 2,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1.9,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 3.2,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {videoData?.lastWatchedCourse !== undefined &&
            videoData?.lastWatchedCourse !== null &&
            videoData?.lastWatchedCourse?.length > 0 &&
            videoData?.lastWatchedCourse?.map((videoData, index) => (
              <ResumeLearningCard
                key={index}
                courseTitle={videoData?.data?.courseId?.courseName}
                courseImageUrl={optimizeImage(
                  videoData?.data?.courseId?.courseThumbnail?.[0]?.resourceId,
                  300,
                  170,
                  60
                )}
                lectureTitle={videoData?.data?.lectureId?.lectureName}
                lectureDescription={
                  videoData?.data?.lectureId?.lectureDescription
                }
                onClick={() => handleCardClick(videoData)}
              />
            ))}
        </Carousel>
      </div>
    </div>
  );
}

export default ResumeLearning;

import React from "react";

function WebLogo({ className }) {
  return (
    <div className={`${className} py-3`}>
      <img src={require("../../assets/Dexlabz/logo.avif")} alt="" />
    </div>
  );
}

export default WebLogo;

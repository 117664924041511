import React from "react";
import SubStoreImageCard from "../CreateThumbnail/SubStoreImageCard";

function ClassroomCourseCard({ courseTitle, courseImageUrl, onClick }) {
  return (
    <div
      className="flex flex-col  min-w-[10rem] lg:min-w-[18rem] me-5  p-1 rounded-md border border-gray-50 m-1 hover:bg-gray-100 cursor-pointer min-h-[10rem] shadow-no-border-shadow-2"
      onClick={onClick}
    >
      <div className="flex justify-center items-center ">
        {/* <img src={courseImageUrl} className="rounded-md" /> */}
        <SubStoreImageCard
          imageUrl={require("../../../assets/ThumbnailsImages/style17.png")}
          backgroundColor="bg-gray-100"
          // heading={data?.courseName}
          // config={imageStyle?.STYLE_1}
          // language={data?.language?.[0]?.languageName}
        />
      </div>
      <div className="flex flex-row flex-wrap justify-between items-start py-1">
        <span className=" md:p-1  text-xs md:text-sm leading-tight line-clamp-2">
          {courseTitle}
        </span>
      </div>
    </div>
  );
}

export default ClassroomCourseCard;

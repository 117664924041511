import React, { useEffect, useState } from "react";
import BrandLogo from "../Shared/BrandLogo/BrandLogo";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { TbPointFilled } from "react-icons/tb";
import CustomRadioBtn from "../Shared/CustomRadioBtn/CustomRadioBtn";
import TestTimer from "./TestTimer";
import OverTimePopup from "./OverTimePopup";
import { useGetQuestionByLectureUidQuery } from "../../../api/ReduxToolkit";
import { useLocation } from "react-router-dom";

function OnlineTest() {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState([
    { content: "", selected: false },
  ]);
  const [testTimeOver, setTestTimeOver] = useState(false);

  const { data } = useGetQuestionByLectureUidQuery(location?.state?.lectureUid);

  const options = [
    {
      content: "<p>krishna</p>\n",
      selected: false,
    },
    {
      content: "<p>kumar</p>\n",
      selected: false,
    },
    {
      content: "<p>gautam</p>\n",
      selected: false,
    },
    {
      content: "<p>ram kumar</p>\n",
      selected: false,
    },
  ];

  // useEffect(() => console.log("select", data?.msg?.[0]?.questions));

  console.log("question = ", data);

  return (
    <div className="w-full flex justify-center text-[#313639] bg-gray-50">
      <div className="flex justify-start w-[95%]  lg:w-[70%] tablet:w-[90%]  h-screen">
        <div className=" w-[68%] flex flex-col px-5 relative">
          <div className="w-full flex  justify-between items-center">
            <BrandLogo className={`!m-0`} />
            {/* <TestTimer initialSeconds={5} setTestTimeOver={setTestTimeOver} /> */}
          </div>
          <div className=" px-5 bg-white p-3 rounded-lg drop-shadow-lg mt-10">
            <p className=" mb-10">Question 1</p>
            <p className="">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptates tempora sit architecto, repudiandae et ut expedita
              maiores temporibus magni, blanditiis maxime amet facilis voluptate
              eligendi repellat tenetur adipisci soluta officia.
            </p>
          </div>
          <div className="w-full my-5 flex justify-between flex-wrap ">
            {options?.map((option, index) => (
              <p
                className="bg-white w-[49%] p-5 my-2 rounded-lg drop-shadow-lg"
                key={index}
              >
                <CustomRadioBtn
                  option={option}
                  arrayIndex={index}
                  options={options}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              </p>
            ))}
          </div>

          <div className="absolute bottom-10 left-0 flex justify-between px-10 w-full">
            <CustomButton
              lable={`Previous`}
              className={`bg-blue-500 text-white border-none !px-10 !py-3`}
            />
            <CustomButton
              lable={`Save & Next`}
              className={`bg-green-500 text-white border-none !px-10 !py-3`}
            />
          </div>
        </div>
        <div className="w-[30%] px-5 flex flex-col ">
          <div className="w-full flex justify-between items-center my-5">
            <p className="font-medium ">Test Type </p>
            <CustomButton
              lable={`End Test`}
              className={`bg-red-400 text-white border-none !px-10 !py-3 !mx-0`}
            />
          </div>
          <div className="">
            <p className="text-xl font-medium">Questions</p>
            <p className="text-sm mt-2">Attempt all</p>
          </div>
          <div className=" flex justify-between my-10">
            <div className="flex items-center text-sm">
              <TbPointFilled className="text-2xl text-green-400" />
              <p className="">0 answered</p>
            </div>
            <div className="flex items-center text-sm">
              <TbPointFilled className="text-2xl text-orange-400" />
              <p className="">0 marked</p>
            </div>
            <div className="flex items-center text-sm">
              <TbPointFilled className="text-2xl text-red-500" />
              <p className="">0 unanswered</p>
            </div>
          </div>

          <div className="flex flex-wrap my-5">
            {data?.msg?.[0]?.questions?.map((count, index) => (
              <p className="border border-gray-300 px-3 py-1.5  rounded-lg m-1 cursor-pointer">
                {index + 1}
              </p>
            ))}
          </div>
        </div>

        {testTimeOver && <OverTimePopup testTimeOver={testTimeOver} />}
      </div>
    </div>
  );
}

export default OnlineTest;

import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { FiDownloadCloud } from "react-icons/fi";
import { HiArrowLongRight } from "react-icons/hi2";
import { IoIosArrowDown, IoIosCheckboxOutline } from "react-icons/io";
import { FaShare } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import CustomButton from "../Shared/CustomButton/CustomButton";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import LightFooter from "../Footer/LightFooter";
import ReactHlsPlayer from "@ducanh2912/react-hls-player";
import {
  useGetAllChaptersBySectionUidQuery,
  useGetAllLecturesByChapterUidQuery,
  useGetLectureByCourseUidQuery,
  useGetLectureByLectureUidQuery,
  useSaveVideoProgressMutation,
} from "../../../api/ReduxToolkit";

import no_video from "../../../assets/Dexlabz/no_video_thumbnail_image.jpg";
import BrandLogo from "../Shared/BrandLogo/BrandLogo";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import Lottie from "lottie-react";
import processing from "../../../assets/Dexlabz/mentinence.json";
import PdfViewer from "../PdfViewer/PdfViewer";
import { ApiConstants } from "../../../api/ApiConstants";
import Footer from "../Footer/Footer";
import SubStoreImageCard from "../CreateThumbnail/SubStoreImageCard";
import PreviewPdf from "./PreviewPdf";
import DoubtBox from "./DoubtBox";

function UcomPlayer() {
  const navigate = useNavigate();
  const [showMoreClassesBox, setShowMoreClasses] = useState("Chapters");
  const [chapterName, setChapterName] = useState("");
  const [currentChapterUid, setCurrentChapterUid] = useState(null);
  const [currentChapterId, setCurrentChapterId] = useState(null);
  const [lectureDescription, setLectureDescription] = useState("");
  const [lectureName, setLectureName] = useState("");
  const [currentLectureUid, setCurrentLectureUid] = useState(null);
  const [currentLectureId, setCurrentLectureId] = useState(null);
  const [showLecturePdfPopup, setShowLecturePdfPopup] = useState(false);
  const [videoPath, setVideoPath] = useState();
  const { userInfo, accessToken } = useContext(AuthContext);
  const [currentLectureData, setCurrentLectureData] = useState();
  const [showDoubtPopup, setShowDoubtPopup] = useState(false);

  const pdfUrl = "https://icseindia.org/document/sample.pdf";

  const playerRef = useRef();

  const location = useLocation();

  const [saveVideoProgress, { data: videoProgressResponse }] =
    useSaveVideoProgressMutation();

  const { data: lectureByCourseId } = useGetLectureByCourseUidQuery(
    location?.state?.courseUid || videoProgressResponse?.data?.courseUid,
    {
      skip: !location?.state?.courseUid,
    }
  );

  const { data: allChapters } = useGetAllChaptersBySectionUidQuery(
    { id: location?.state?.sectionUid, token: accessToken },
    {
      skip: !location?.state?.sectionUid,
    }
  );

  const { data: allLectures } = useGetAllLecturesByChapterUidQuery(
    { id: currentChapterUid, token: accessToken },
    {
      skip: !currentChapterUid,
    }
  );

  const { data: playerData } = useGetLectureByLectureUidQuery(
    currentLectureUid,
    {
      skip: !currentLectureUid,
    }
  );

  console.log("chapter id", currentChapterUid);

  useEffect(() => {
    const chapterData = allChapters?.sections?.chapters?.find(
      (chapter) => location?.state?.chapterId === chapter?._id
    );
    if (chapterData) {
      handleMoreClasses("Lectures");
      setCurrentChapterUid(chapterData?.chapterUid);
      setChapterName(chapterData?.chapterName);
      setCurrentChapterId(chapterData?._id);
    }
  }, [allChapters]);

  useEffect(() => {
    const lectureData = allLectures?.chapters?.lectures?.find(
      (lecture) => location?.state?.lectureUid === lecture?.lectureUid
    );

    if (lectureData) {
      console.log("set huaa hai ", lectureData);
      setCurrentLectureUid(lectureData?.lectureUid);
      setLectureDescription(lectureData?.lectureDescription);
      setLectureName(lectureData?.lectureName);
      setCurrentLectureId(lectureData?._id);
    }
  }, [allLectures]);

  const handleMoreClasses = (clickData) => {
    if (clickData === "Chapters") {
      setShowMoreClasses("Chapters");
      setChapterName("");
    }
    if (clickData === "Lectures") {
      setShowMoreClasses("Lectures");
    }
  };

  const handleClickChapterCard = (chapters) => {
    setChapterName(chapters?.chapterName);
    setCurrentChapterId(chapters?._id);
    setCurrentChapterUid(chapters?.chapterUid);
  };

  useEffect(() => {
    setVideoPath(
      playerData?.data?.detail?.outputGroupDetails?.[0]?.streamingUrl
    );
  }, [playerData]);

  const handleClickLectureCard = (lecture) => {
    // console.log("lecture data", lecture);
    setLectureName(lecture?.lectureName);
    setLectureDescription(lecture?.lectureDescription);
    setCurrentLectureId(lecture?._id);
    setCurrentLectureUid(lecture?.lectureUid);
    setCurrentLectureData(lecture);

    saveVideoProgress({
      body: {
        data: {
          courseId: location?.state?.duration
            ? location.state.courseId
            : location?.state?.courseData?._id,
          lectureId: currentLectureId,
          sectionId: location?.state?.sectionId,
          chapterId: currentChapterId,
          duration: playerRef?.current?.currentTime,
          totalVideoDuration: playerRef?.current?.duration,
        },
        userId: userInfo?.uid,
      },
    });
  };

  useEffect(() => {
    if (showDoubtPopup) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = ""; // Enable scroll
    }
    // Cleanup on component unmount or popup close
    return () => {
      document.body.style.overflow = "";
    };
  }, [showDoubtPopup]);

  // const handleShowPdfViewer = () => {
  //   const pdfUrl = "https://icseindia.org/document/sample.pdf";
  //   window.open(pdfUrl, "_blank");
  // };

  // console.log("lecture Data", allLectures);

  return (
    <div>
      <Toolbar />
      <div className="flex justify-center">
        <div className=" w-[95%] lg:w-[65%] tablet:w-[90%] my-10">
          <div className="flex flex-col  justify-between tablet:flex-col lg:flex-row">
            <div className="flex flex-col  items-start w-full lg:w-[65%]  tablet:w-full">
              <div className="flex text-xs md:text-sm font mt-3 items-center ">
                {(location?.state?.courseData?.courseName !== undefined ||
                  location?.state?.courseName) && (
                  <p className="mx-1 flex items-center">
                    <span className="mx-1 capitalize cursor-pointer hover:underline">
                      {location?.state?.duration
                        ? location.state.courseName
                        : location?.state?.courseData?.courseName}
                    </span>
                    <HiArrowLongRight className="mx-1 text-lg" />
                    <span className="mx-1 capitalize cursor-pointer hover:underline">
                      {location?.state?.sectionName}
                    </span>
                  </p>
                )}

                {chapterName !== "" && (
                  <p className="mx-1 flex items-center">
                    <HiArrowLongRight className="mx-1 text-lg" />
                    <span className="mx-1 capitalize cursor-pointer hover:underline">
                      {chapterName}
                    </span>
                  </p>
                )}
              </div>
              <div className="  w-full   my-3 mt-10 rounded-md ">
                {videoPath !== undefined && (
                  <ReactHlsPlayer
                    onLoadedData={() => {
                      const duration = location?.state?.duration;
                      if (playerRef.current && duration) {
                        playerRef.current.currentTime = duration;
                      }
                    }}
                    playerRef={playerRef}
                    autoPlay={false}
                    src={`${ApiConstants?.cloudFront}${videoPath}`}
                    controls={true}
                    width="100%"
                    className="rounded-lg shadow-sm "
                  />
                )}
                {videoPath === undefined && (
                  <div className="w-full lg:h-[20rem] z-10 relative ">
                    <img
                      src={no_video}
                      alt=""
                      className="w-full lg:h-[20rem] rounded-lg"
                    />
                    <span className=" text-2xl font-xl font-semibold absolute text-center top-0 bottom-0 left-0 right-0  text-white   flex justify-center items-center">
                      No Video
                    </span>
                  </div>
                )}
              </div>
              {location?.state?.courseData?.language?.[0]?.languageCode && (
                <div className="flex items-center lg:text-xs tablet:text-xs text-xsm ">
                  <p className=" border p-1 rounded-md ">
                    {location?.state?.courseData?.language?.[0]?.languageCode}
                  </p>
                  <p className="mx-3 font-bold text-purple-400">
                    {location?.state?.courseData?.courseType?.[0]}
                  </p>
                </div>
              )}
              {lectureName !== "" && (
                <p className="font-semibold text-lg my-3 capitalize">
                  {lectureName}
                </p>
              )}{" "}
              {lectureDescription !== "" && (
                <p className="text-sm text-gray-600 capitalize">
                  {lectureDescription}
                </p>
              )}
              <div className=" my-4 flex flex-row justify-between lg:items-center w-full border-b pb-3">
                <div className="flex items-start">
                  <img
                    src={require("../../../assets/Dexlabz/quest-short-logo.png")}
                    alt=""
                    className="p-2 bg-gradient-to-b from-white to-orange-300 rounded-full lg:w-[3rem] w-[2.5rem]"
                  />
                  <p className="font-bold lg:text-lg text-sm text-gray-700  mt-2 capitalize">
                    {/* {
                      location?.state?.courseData?.educatorInfo?.[0]
                        ?.educatorName
                    } */}
                    Uday Kishor
                  </p>
                </div>

                <div className="flex items-center">
                  {/* {currentLectureId !== null && (
                    <CustomButton
                      lable={`Test`}
                      onClick={() =>
                        navigate("/test", {
                          state: {
                            lectureUid: location?.state?.lectureUid,
                          },
                        })
                      }
                    />
                  )} */}

                  <CustomButton
                    lable={`Doubt`}
                    className={`!mx-0 w-[5rem] bg-green-500 hover:bg-green-500/90 text-white border-none`}
                    onClick={() => setShowDoubtPopup(true)}
                  />

                  {showMoreClassesBox === "Lectures" && (
                    <div>
                      <CustomButton
                        lable={`View Notes `}
                        className={`  !me-0 bg-green-500 hover:bg-green-500/90 text-white border-none`}
                        onClick={() => setShowLecturePdfPopup(true)}
                      />
                    </div>
                  )}
                  {/* <CustomButton lable={<FaShare />} /> */}
                </div>
              </div>
              {/* <div className="border-b pb-3 w-full text-xs lg:text-sm  font-semibold text-gray-400">
                <p className="mb-2">ul 23, 2021 • 56m • 290 views</p>
                <p className="">
                  In this class, Lectures Singh will be talking about retaining
                  walls in detail.
                </p>
              </div> */}
            </div>

            <div className="  lg:w-[33%] text-gray-400 flex flex-col  ">
              <p
                className="mx-1 text-sm my-3 font-bold self-start flex items-center text-[#313639] cursor-pointer hover:text-blue-600"
                onClick={() => navigate("/classroom")}
              >
                <IoIosArrowDown className="mx-1 text-lg rotate-90" />
                Go to Classroom
              </p>

              <div className="flex flex-row mt-10 my-5 self-start border rounded-full lg:ms-5">
                <p
                  className={`!py-1 !px-5  mx-auto  rounded-full  cursor-pointer  ${
                    showMoreClassesBox === "Chapters"
                      ? "font-medium  bg-green-600 text-white"
                      : ""
                  }`}
                  onClick={() => handleMoreClasses("Chapters")}
                >
                  Chapters
                </p>
                <p
                  className={`!py-1 !px-5  mx-auto  rounded-full  cursor-pointer  ${
                    showMoreClassesBox === "Lectures"
                      ? "font-medium  bg-green-600 text-white"
                      : ""
                  }`}
                  onClick={() => handleMoreClasses("Lectures")}
                >
                  Lectures
                </p>
              </div>

              {showMoreClassesBox === "Chapters" && (
                <div className="overflow-auto lg:h-[20rem] flex flex-row lg:flex-col ">
                  {allChapters?.sections?.chapters &&
                  allChapters?.sections?.chapters.length > 0 ? (
                    // Check if all chapters are unpublished
                    allChapters?.sections?.chapters.every(
                      (chapter) => !chapter?.isPublished
                    ) ? (
                      <div className="w-full flex flex-col justify-center items-center my-auto">
                        <Lottie
                          animationData={processing}
                          loop={true}
                          className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
                        />
                        <div className="text-sm font-medium text-[#313639]">
                          Work is going on under-processing
                        </div>
                      </div>
                    ) : (
                      allChapters?.sections?.chapters?.map(
                        (chapter, index) =>
                          chapter?.isPublished ? (
                            <div
                              className={`flex flex-col lg:flex-row p-1 mx-1 lg:mx-5 my-1 hover:bg-gray-50 cursor-pointer rounded-md min-w-[8rem] tablet:min-w-[10rem] shadow`}
                              key={index}
                              onClick={() => {
                                handleMoreClasses("Lectures");
                                handleClickChapterCard(chapter);
                              }}
                            >
                              <div className=" lg:w-[35%]">
                                <SubStoreImageCard
                                  imageUrl={require("../../../assets/ThumbnailsImages/style17.png")}
                                  backgroundColor="bg-gray-100"
                                  boxHight="h-[5rem]"
                                />
                              </div>
                              <div className="lg:ms-3 mt-2 lg:mt-0 lg:w-[65%]">
                                <p className="text-gray-600 font-medium my-auto text-xs lg:text-base line-clamp-1">
                                  {chapter.chapterName}
                                </p>
                                <p className="text-xs my-1 text-xsm lg:text-xs line-clamp-3">
                                  {chapter.chapterDescription}
                                </p>
                              </div>
                            </div>
                          ) : null // Skip unpublished chapters
                      )
                    )
                  ) : (
                    <div className="w-full flex flex-col justify-center items-center my-auto">
                      <Lottie
                        animationData={processing}
                        loop={true}
                        className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
                      />
                      <div className="text-sm font-medium text-[#313639]">
                        Work is going on under-processing
                      </div>
                    </div>
                  )}
                </div>
              )}

              {showMoreClassesBox === "Lectures" && (
                <div className="overflow-auto  lg:h-[20rem] flex flex-row lg:flex-col gap-3 lg:gap-0 lg:px-3">
                  {allLectures?.chapters?.lectures &&
                  allLectures?.chapters?.lectures.length > 0 ? (
                    allLectures?.chapters?.lectures?.map((lecture, index) => (
                      <div
                        className={` flex flex-col lg:flex-row p-1 px-1  lg:mx-0 my-1 hover:bg-gray-50 cursor-pointer rounded-md    min-w-[8rem] tablet:min-w-[10rem] w-full shadow  ${
                          !lecture?.isPublished ? "hidden" : "block"
                        }`}
                        key={index}
                        onClick={() => handleClickLectureCard(lecture)}
                      >
                        <div className="w-full lg:w-[35%]">
                          {lecture?.Thumbnail ? (
                            <img
                              src="https://static.uacdn.net/thumbnail/course/v2/A59E78DC-DC0C-4DD4-A760-35856B45E422_special_class.png?q=75&w=512&auto=format%2Ccompress"
                              alt=""
                              className="rounded-md "
                            />
                          ) : (
                            <img
                              // src={`${ApiConstants?.cloudFront}${card?.detail?.thumbnails?.[0]?.thumbnail_url}`}
                              src={`https://api.quest4physics.com/service/resource/getLectureThumbnail/${lecture?.lectureUid}`}
                              alt=""
                              className="rounded-md"
                            />
                          )}
                        </div>
                        <div className="lg:ps-3 pt-2 lg:pt-0 lg:w-[65%]">
                          <p className="text-gray-600 font-medium my-auto text-xs lg:text-sm capitalize line-clamp-3  lg:line-clamp-2">
                            {lecture?.lectureName}
                          </p>
                          {/* <p className="text-xs  my-1 text-xsm lg:text-sm">
													{lecture.teacherName}
												</p> */}
                          {/* <p className="text-xs  my-auto text-xsm lg:text-xs">
													{card.date} • {card.time}
												</p> */}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className=" w-full flex flex-col justify-center items-center my-auto  ">
                      <Lottie
                        animationData={processing}
                        loop={true}
                        className="w-[150px] h-[150px] md:w-[200px] md:h-[200px] "
                      />
                      <div className=" text-sm font-medium text-[#313639]">
                        Work is going on under-processing
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between items-end  pb-5 ps-5 md:ps-0 border-b ">
            <div className="">
              <p className=" lg:text-2xl text-gray-700 font-bold ">
                Chapters subject-wise courses
              </p>
              <div className="flex items-center mt-1 text-xsm lg:text-sm text-gray-400 font-semibold">
                <p className="flex items-center me-2">
                  <IoIosCheckboxOutline className="me-3 text-lg" /> Best for
                  deep diving in a subject
                </p>
                <p className="flex items-center me-2">
                  <IoIosCheckboxOutline className="me-3 text-lg" /> Learn a
                  subject from your favourite educator
                </p>
              </div>
            </div>
          </div>
          {/* <div className="w-full flex flex-row flex-wrap items-center justify-start bg-red-400 ">
            {lectureByCourseId?.data?.map((card, index) => (
              <div
                className=" flex flex-col p-1 lg:mx-3 my-5 mx-auto hover:bg-gray-50 cursor-pointer w-[40%] lg:w-[21.5%] rounded-md "
                key={index}
              >
                <div className="">
                  <img
                    src={`${ApiConstants?.cloudFront}${card?.detail?.thumbnails?.[0]?.thumbnail_url}`}
                    alt=""
                    className="rounded-md"
                    onError={() => setHasShowImageError(true)}
                  />
                </div>
                <div className="mx-2 text-gray-400">
                  <div className="flex items-center text-xsm my-2 justify-between">
                    <p className=" font-bold text-purple-400 text-xsm ">
               abc
                    </p>
                    <p className=" border p-1 rounded-md  text-xsm text-gray-600">
                      Hinglish
                    </p>
                  </div>
                  <p className="text-gray-600 font-semibold my-auto text-xs lg:text-base tablet:text-sm">
                    {card.subject}
                  </p>
                  <p className="text-xs  my-1 text-xsm lg:text-sm tablet:text-xs">
                    {card.teacherName}
                  </p>
                  <p className="text-xs  my-auto text-xsm lg:text-xs tablet:text-xs">
                    {card.date} • {card.time}
                  </p>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <Footer />

      {showLecturePdfPopup && (
        <PreviewPdf
          bottomPopup={`animate-bottomPopup`}
          currentLectureData={currentLectureData}
          setShowLecturePdfPopup={setShowLecturePdfPopup}
        />
      )}
      {showDoubtPopup && (
        <DoubtBox
          animationClass="animate-rightPopup" // Your animation class
          setShowDoubtPopup={setShowDoubtPopup} // Passing the function
        />
      )}
    </div>
  );
}

export default UcomPlayer;

import React from "react";

function CustomButton({
  onClick,
  lable,
  className,
  children,
  hasIcon = false,
  disabled,
}) {
  return hasIcon ? (
    <button
      className={` text-xs md:text-sm px-3 md:px-5 py-2 font-medium border border-black  rounded-lg mx-3  ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <p className="flex items-center">
        {children} <span className="mx-4"> {lable}</span>
      </p>
    </button>
  ) : (
    <button
      className={` text-xs md:text-sm px-3 md:px-5 py-2 font-medium border border-black  rounded-lg mx-3  ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {lable}
    </button>
  );
}

export default CustomButton;

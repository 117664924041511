import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { useSetUserGoalMutation } from "../../../../../api/ReduxToolkit";
import { AuthContext } from "../../../../../providers/AuthProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PdfLoader from "../../../UcomPlayer/PdfLoader";

function PickLanguage({
  animationClass,
  setShowLanguageBox,
  subGoalList,
  currentIndex,
  currentSelected,
  currentGoalId,
}) {
  // console.log(
  //   "pickLanguage me SubGolaList ki value : ",
  //   subGoalList[0].language
  // );
  const { accessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  // console.log("subgoal", subGoalList);
  const [languageBox, setLanguageBox] = useState("");
  const [setUserGoal, { isError, error, isLoading, isSuccess }] =
    useSetUserGoalMutation();

  const handleSetUserGoal = async () => {
    try {
      const response = await setUserGoal({
        body: {
          goalInfo: {
            id: currentGoalId,
          },
        },
        token: accessToken,
      });

      //   console.log(response);

      if (response) {
        // console.log(" data =  ", response?.data?.msg);
        toast.success(response?.data?.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setTimeout(() => {
          navigate("/"); // Perform the navigation after 3 seconds
        }, 3000); //
      } else {
        console.log("Oops! user is not logid ");
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  useEffect(() => {
    if (error?.data && isError) {
      toast.error(error?.data?.msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [error, isError]);

  console.log("language", error?.data?.msg);

  return (
    <div className="  top-0 left-0 w-screen h-screen bg-[rgba(128,128,128,.8)] flex justify-end items-center  z-50 cursor-default fixed ">
      {isLoading && <PdfLoader />}
      <div
        className={`w-[80%] lg:w-[30%] tablet:w-[50%] bg-blue-200   min-h-full md:h-screen relative transition-all duration-700  ${animationClass}`}
      >
        <ToastContainer />

        <AiOutlineClose
          className="absolute text-3xl right-3 top-3 md:right-5 md:top-2 cursor-pointer  p-1.5 rounded-full bg-gray-400  hover:scale-105 duration-300 text-white hover:rotate-90"
          onClick={() => setShowLanguageBox(false)}
        />
        <div className=" p-5 pt-10  md:p-10">
          <div className=" mb-10">
            <p className="text-xl tablet:text-2xl lg:text-4xl font-bold mb-3">
              Pick a goal Language
            </p>
            <p className="text-xs md:text-sm ">
              Courses in the preferred language will be shown first
            </p>
          </div>

          <div className="flex flex-col md:flex-row flex-wrap justify-between">
            {subGoalList[currentIndex]?.language?.map((language, index) => (
              <span
                key={index}
                onClick={() => setLanguageBox(language?.languageName)}
                className={`w-full md:w-[45%] h-[7rem] md:h-[13rem]  relative rounded-lg mt-5 duration-200 bg-[rgba(255,246,223)] ${
                  languageBox === language.languageName
                    ? "border-4 border-orange-300 scale-105"
                    : ""
                } `}
              >
                <CustomButton
                  lable={language?.languageName}
                  className={`mt-5 border-none !text-xl`}
                />
                {language?.languageName === "English" && (
                  <img
                    src={require("../../../../../assets/Dexlabz/English.png")}
                    className="absolute bottom-5 right-0 w-[50%]"
                    alt=""
                  />
                )}{" "}
                {language?.languageName === "Hindi" && (
                  <img
                    src={require("../../../../../assets/Dexlabz/Hindi.png")}
                    className="absolute bottom-5 right-3 w-[50%]"
                    alt=""
                  />
                )}{" "}
                {language?.languageName === "Hinglish" && (
                  <img
                    src={require("../../../../../assets/Dexlabz/hiE.png")}
                    className="absolute bottom-5 right-3 w-[70%]"
                    alt=""
                  />
                )}
              </span>
            ))}
          </div>
        </div>
        {languageBox === "" ? (
          <CustomButton
            lable={`Set Goal`}
            className={`bg-gray-600/50 border-none text-white absolute bottom-10 right-5 !text-lg !px-16 !py-5`}
            disabled
          />
        ) : (
          <CustomButton
            lable={`Set Goal`}
            className={`bg-gray-900/80 border-none text-white absolute bottom-10 right-5 !text-lg !px-16 !py-5`}
            onClick={() => handleSetUserGoal()}
          />
        )}
      </div>
    </div>
  );
}

export default PickLanguage;

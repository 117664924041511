import React from "react";
import { WebConstant } from "../../../api/ApiConstants";
import BrandLogo from "../Shared/BrandLogo/BrandLogo";
import {
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiLogoYoutube,
  BiSolidPhoneCall,
} from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import WebLogo from "../../WebLogo/WebLogo";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
function Footer() {
  const navigate = useNavigate();

  const useFullLinks = [
    {
      link: "",
      title: "About",
    },
    {
      link: "/terms-conditions",
      title: "Terms & Conditions",
    },
    {
      link: "/refund-policy",
      title: "Refund-Policy",
    },
    {
      link: "/privacy-policy",
      title: "Privacy-Policy",
    },
  ];

  return (
    <div className="flex  justify-center mt-10 border-t">
      <div className="w-[90%] lg:w-[70%] tablet:w-[85%]  flex justify-between flex-col ">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-[30%]  lg:px-5 pb-5  me-5">
            <div className="">
              <div className="">
                {/* <BrandLogo /> */}

                <WebLogo className={`w-[10rem]`} />
              </div>
              <p className="text-xs lg:text-sm my-5 text-gray-400">
                {WebConstant?.WEB_NAME} is India’s largest online learning
                platform. Download our apps to start learning
              </p>
              <div className="flex items-center justify-start  my-5">
                <img
                  src={require("../../../assets/Dexlabz/app-store.png")}
                  alt=""
                  className="w-[30%] md:w-[40%]"
                />
                <img
                  src={require("../../../assets/Dexlabz/PlayStore.png")}
                  alt=""
                  className="w-[30%] md:w-[40%]"
                />
              </div>
            </div>
            {/* <div className="flex flex-col item mt-16">
              <p className=" font-semibold text-sm tablet:text-sm lg:text-base mb-2">
                Starting your preparation?
              </p>
              <p className="text-xs lg:text-sm mb-5 text-gray-400">
                Call us and we will answer all your questions about learning on{" "}
                {WebConstant?.WEB_NAME}
              </p>
              <p className="flex items-center   font-mono cursor-pointer text-xs">
                <BiSolidPhoneCall className="mx-2" /> Call +91 9876543210
              </p>
            </div> */}
          </div>
          <div className="w-full md:w-[50%]  lg:px-5 py-5 flex flex-col lg:flex-row justify-between ">
            <div className="flex flex-col w-full md:w-[50%]">
              <p className="font-bold text-gray-700">Usefull Links </p>
              <div className="mt-5">
                {useFullLinks?.map((items, index) => (
                  <p
                    key={index}
                    className=" cursor-pointer text-gray-600 mb-2 hover:text-gray-800"
                    onClick={() => navigate(`${items?.link}`)}
                  >
                    {items?.title}
                  </p>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full md:w-[50%] mt-5 md:mt-0">
              <p className="font-bold text-gray-700">Contact Us </p>
              <div className="mt-5 flex flex-col gap-3 text-xs">
                <div className="flex items-center gap-5">
                  <FaPhoneAlt />
                  <p className="">+91 7292986896</p>
                </div>{" "}
                <div className="flex items-center gap-5 ">
                  <MdOutlineMailOutline className="size-4" />
                  <p className="">support@quest4physics.com</p>
                </div>{" "}
                <div className="flex items-center gap-5 ">
                  <SlLocationPin className="size-5" />
                  <p className="">
                    Opposite Chowk Thana <br /> Naer Kangan Ghat, <br />
                    Jhauganj Patna City, <br /> PIN-800008, Bihar
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;

import React, { useEffect, useRef, useState } from "react";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";

import ChooseCourse from "./ChooseCourse";
import ChooseSection from "./ChooseSection";
import ResumeLearning from "./ResumeLearning";

function Classroom() {
  const [currentCourseData, setCurrentCourseData] = useState([]);
  const [showSectionBox, setShowSectionBox] = useState(false);

  return (
    <div className="flex justify-center flex-col">
      <div className="sticky top-0 z-50 bg-white">
        <Toolbar />
      </div>

      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] self-center flex justify-between items-start  my-10 px-3">
        <div className="w-full  lg:w-[70%]">
          <ChooseCourse
            setCurrentCourseData={setCurrentCourseData}
            setShowSectionBox={setShowSectionBox}
            showSectionBox={showSectionBox}
          />
          <ResumeLearning setCurrentCourseData={setCurrentCourseData} />
        </div>
        <div
          className={`w-[80%] tablet:w-[50%] lg:w-[30%] bg-[#FAF8F3] h-[85vh] overflow-y-auto no-scrollbar rounded-2xl lg:rounded-l-2xl animate-rightPopup absolute right-0 lg:static  ${
            showSectionBox ? "block" : "hidden lg:block"
          }`}
        >
          <ChooseSection currentCourseData={currentCourseData} />
        </div>
      </div>
    </div>
  );
}

export default Classroom;

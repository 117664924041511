import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiConstants } from "../api/ApiConstants";
import axios from "axios";

const initialState = {
  userInfo: {},
  loading: false,
  error: false,
  errMsg: "",
};

export const LoginThunk = createAsyncThunk("user/login", (payload) => {
  return axios
    .post(ApiConstants.BASE_URL + "" + ApiConstants.LOGIN_END_POINT, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return {
        serverCode: err.response.status,
        message: err.response.data.message,
      };
    });
});

const LoginSlice = createSlice({
  name: "loginReducer",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.errMsg = action.payload;
      state.error = true;
    },
    clearError: (state, action) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LoginThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(LoginThunk.fulfilled, (state, action) => {
      state.loading = false;
      // console.log(action.payload);
      if (
        action.payload.serverCode === 200 ||
        action.payload.serverCode === 201
      ) {
        state.userInfo = action.payload;
        state.errMsg = "";
        state.error = false;
      } else {
        state.errMsg = action?.payload?.message;
        state.error = true;
        state.userInfo = {};
      }
    });
    builder.addCase(LoginThunk.rejected, (state, action) => {
      state.loading = false;
      state.userInfo = {};
      state.error = action.error.message;
    });
  },
});

export const { setError, clearError } = LoginSlice.actions;
export default LoginSlice.reducer;

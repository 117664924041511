import React, { useState } from "react";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import SubStoreHero from "./SubStoreHero";
import SubStoreAbout from "./SubStoreAbout";
import ScheduleStore from "./ScheduleStore";
import EducatorsStore from "./EducatorsStore";
import LearnStore from "./LearnStore";
import QuestionsStore from "./QuestionsStore";
import DarkFooter from "../Footer/DarkFooter";
import { connect } from "react-redux";
import Footer from "../Footer/Footer";

function SubStore({ setShowSubStore, currentCradData, activeGoalData }) {
  const [accessAllowed, setAccessAllowed] = useState(false);
  const [isData, setIsData] = useState();
  // console.log("activeGoalData", currentCradData);

  const checkAccess = (isAllowed) => {
    setAccessAllowed(isAllowed);
  };

  return (
    <div>
      <div className="">
        <Toolbar />
      </div>
      <div className="flex justify-center">
        <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex flex-col justify-center items-center ">
          <div className="w-full lg:w-[90%] my-5">
            <SubStoreHero
              setShowSubStore={setShowSubStore}
              currentCradData={currentCradData}
              checkAccess={checkAccess}
              setIsData={setIsData}
            />
          </div>
          <div className="w-full lg:w-[90%] my-5 ">
            <SubStoreAbout
              currentCradData={currentCradData}
              accessAllowed={accessAllowed}
              isData={isData}
            />
          </div>

          {/* <div className="w-full lg:w-[90%] my-5 ">
            <FreeLectures currentCradData={currentCradData} />
          </div> */}
          {/* <div className="w-full lg:w-[90%] my-5 ">
            <ScheduleStore currentCradData={currentCradData} />
          </div> */}
          {/* <div className="w-full lg:w-[90%] my-5 ">
            <EducatorsStore
              currentCradData={currentCradData}
              activeGoalData={activeGoalData}
            />
          </div> */}
          {/* <div className="w-full lg:w-[90%] my-5 ">
            <LearnStore />
          </div> */}

          {/* <div className="w-full lg:w-[90%] my-5 ">
						<QuestionsStore />
					</div> */}
        </div>
      </div>
      <div className=" ">
        <Footer />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    activeGoalData: state?.userSelectGoalReducer?.userGoalInfo?.activeGoal,
    loading: state?.userSelectGoalReducer?.loading,
  };
};
export default connect(mapStateToProps)(SubStore);

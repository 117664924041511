import React, { useContext, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import FreeLectures from "./FreeLecturesVideoPlayer";
import CourseImageCard from "../CreateThumbnail/CourseImageCard";
import FreeLecturesVideoPlayer from "./FreeLecturesVideoPlayer";
import { AuthContext } from "../../../providers/AuthProvider";
import {
  useGetAllLecturesByChapterUidQuery,
  useGetLectureDataByCourseUidQuery,
} from "../../../api/ReduxToolkit";
import { AiOutlineClose } from "react-icons/ai";
import Lottie from "lottie-react";
import processing from "../../../assets/Dexlabz/mentinence.json";

function FreeLecturePopup({ setShowFreeLecturePopup, rightPopup, courseData }) {
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [currentLectureData, setCurrentLectureData] = useState();

  const { data: allLectures } = useGetLectureDataByCourseUidQuery(
    { id: courseData?._id },
    {
      skip: !courseData?._id,
    }
  );

  // console.log("courseId", courseData?._id);
  console.log("courseId", allLectures?.[0]?.uids);

  const handleLectureCardClick = (lecture) => {
    setCurrentLectureData(lecture);
    setShowVideoPlayer(true);
  };

  return (
    <div className="bg-[rgba(128,128,128,.6)] w-full h-screen fixed top-0 left-0 z-50 ">
      <div
        className={`w-[95%] lg:w-[30%] 2xl:w-[35%] 3xl:w-[30%] 6xl:w-[20%] tablet:w-[70%] 2sm:w-[50%] bg-white   min-h-full md:h-screen  absolute right-0 top-0  transition-all duration-700  ${rightPopup}`}
      >
        <AiOutlineClose
          className="absolute text-3xl right-3 top-3 md:right-10 md:top-5 cursor-pointer  p-1.5 rounded-full bg-gray-400 text-white hover:scale-105 duration-300  hover:rotate-90"
          onClick={() => setShowFreeLecturePopup(false)}
        />

        {/* Modal content */}
        <div className="mt-5 p-5">
          <h2 className="text-2xl font-semibold mb-4">Free Lecture</h2>

          <div className="h-[85vh] overflow-y-scroll no-scrollbar">
            {allLectures?.[0]?.uids && allLectures?.[0]?.uids.length > 0 ? (
              allLectures?.[0]?.uids?.map((lecture, index) => (
                <CourseImageCard
                  key={index}
                  imageUrl={require("../../../assets/ThumbnailsImages/style17.png")}
                  backgroundColor="bg-indigo-100"
                  heading={lecture?.lectureName}
                  language={`Hinglish`}
                  className="w-full mb-5 cursor-pointer !rounded-lg"
                  onClick={() => handleLectureCardClick(lecture)}
                />
              ))
            ) : (
              <div className=" w-full flex flex-col justify-center items-center my-auto  ">
                <Lottie
                  animationData={processing}
                  loop={true}
                  className="w-[150px] h-[150px] md:w-[200px] md:h-[200px] "
                />
                <div className=" text-sm font-medium text-[#313639]">
                  Work is going on under-processing
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showVideoPlayer && (
        <FreeLecturesVideoPlayer
          bottomPopup={`animate-bottomPopup`}
          setShowVideoPlayer={setShowVideoPlayer}
          currentLectureData={currentLectureData}
          courseData={courseData}
        />
      )}
    </div>
  );
}

export default FreeLecturePopup;

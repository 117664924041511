import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import { ApiConstants, WebConstant } from "../../../api/ApiConstants";
import Footer from "../Footer/Footer";
import { useRef, useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import prevIcon from "../../../assets/Dexlabz/prev-icon.png";
import nextIcon from "../../../assets/Dexlabz/next-icon.png";

// import video11 from "../../../assets/video_data/UncleVideo.mp4";

const SuccessStories = () => {
  const ref = useRef();
  const videoRefs = useRef([]); // Store references to all video elements
  const [activeIndex, setActiveIndex] = useState(0); // Track active video index

  const handleScroll = (id) => {
    const item = document.getElementById(id);
    if (item) {
      item.scrollIntoView({ behavior: "smooth" });
    }
  };

  const showNext = () => {
    ref.current.onClickNext();
  };

  const showPrevious = () => {
    ref.current.onClickPrev();
  };

  const videoSliderData = [
    { url: "Ayushivideo1.mp4", type: "video" },
    { url: "AnilKumar.mp4", type: "video" },
    { url: "Raj_Jaiswal.mp4", type: "video" },
    { url: "manyaSinha.mp4", type: "video" },
  ];

  // Autoplay and pause videos based on the active slide
  const handleChange = (index) => {
    setActiveIndex(index); // Update the active index when the slide changes
  };

  useEffect(() => {
    // Pause all videos and play only the current active video
    videoRefs.current.forEach((video, index) => {
      if (index === activeIndex) {
        video?.play(); // Play the active video
      } else {
        video?.pause(); // Pause other videos
      }
    });
  }, [activeIndex]);

  // Play the first video when the component mounts
  useEffect(() => {
    if (videoRefs.current[0]) {
      videoRefs.current[0]?.play();
    }
  }, []);

  return (
    <div className="flex justify-center flex-col">
      <div className="sticky top-0 z-50 bg-white">
        <Toolbar handleScroll={handleScroll} />
      </div>

      <div className="w-[95%] tablet:w-[80%] lg:w-[70%] self-center flex justify-center items-center flex-col my-10 px-3">
        <p className="text-xl md:text-4xl lg:w-[90%] text-center font-bold mb-5 text-gray-700">
          {WebConstant?.WEB_NAME} is the perfect place for your preparation
        </p>
        <p className="mx-5 md:mx-0 text-xs md:text-sm">
          Over <span className="text-green-500">10 crore</span> learners trust
          us for their preparation
        </p>
      </div>

      {/* Video Carousel with Navigation */}
      <div className="relative flex justify-center ">
        {/* Navigation Buttons */}
        <div className="absolute flex w-[87%] tablet:w-[80%] lg:w-[78%] justify-between m-auto left-0 right-0 top-0 bottom-0 z-20 pointer-events-none">
          <button
            className="p-2 rounded-2xl pointer-events-auto"
            onClick={showPrevious}
          >
            <img
              src={prevIcon}
              alt="Previous"
              className="w-[50%] tablet:w-[75%] lg:w-[90%] hover:scale-125 duration-300"
            />
          </button>
          <button
            className="p-2 rounded-2xl pointer-events-auto"
            onClick={showNext}
          >
            <img
              src={nextIcon}
              alt="Next"
              className="w-[50%] tablet:w-[75%] lg:w-[90%] hover:scale-125 duration-300"
            />
          </button>
        </div>

        {/* Video Carousel */}
        <Carousel
          ref={ref}
          centerMode={true}
          showThumbs={false}
          infiniteLoop={true}
          dynamicHeight={false}
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          className="rounded-md z-10"
          centerSlidePercentage={70}
          selectedItem={activeIndex}
          onChange={handleChange} // Trigger when carousel slide changes
        >
          {videoSliderData?.map((item, index) => (
            <div
              key={index}
              className="w-[100%] tablet:w-[90%] h-[7rem] tablet:h-[15rem] md:h-[30rem] px-2 pe-5 md:pe-0 lg:px-16 tablet:px-5 rounded-md z-30"
            >
              <video
                ref={(el) => (videoRefs.current[index] = el)} // Store video ref
                className="w-full h-full object-fill rounded-3xl"
                src={`${ApiConstants?.cloudFront}video-assets/${item.url}`}
                controls
                muted
              />
            </div>
          ))}
        </Carousel>
      </div>

      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default SuccessStories;

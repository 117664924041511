import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import EnableRating from "../UnacademyComponents/EnableRating";
import Slider from "../UnacademyComponents/Slider";
import SubscribeAdvertisment from "../UnacademyComponents/SubscribeAdvertisment";
import FeaturesContainer from "../FeaturesContainer/FeaturesContainer";
import ScholarTest from "../ScholarTest/ScholarTest";
import DarkFooter from "../Footer/DarkFooter";
import TopEducatorSection from "../UnacademyComponents/TopEducatorSection";
import ReferAfriend from "../UnacademyComponents/ReferAfriend";
import CentersCase from "../UnacademyComponents/CentersCase";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { UserSelectGoalThunk } from "../../../redux/UserSelectGoalSlice";
import { AuthContext } from "../../../providers/AuthProvider";
import CoursesShow from "../UnacademyComponents/CoursesShow";
import DashBoardLoder from "../DashBoardLoder/DashBoardLoder";
import AppStateReducer from "../../../redux/AppStateReducer";
import ActiveGoalTitle from "../ActiveGoalTitle/ActiveGoaltitle";
import Footer from "../Footer/Footer";
import VideoSlider from "../VideoSlider/VideoSlider";

const DashBoard = ({}) => {
  const { accessToken } = useContext(AuthContext);

  // console.log("token dashboard me ",accessToken)

  const [isDashBoardLogin, setIsDashBoardLogin] = useState(false);

  const dispatch = useDispatch();

  const { showGlobalProgressIndicator } = useSelector(
    (state) => state.appState
  );

  const SubjectWiseCourse = [
    "Best for IIT JEE online coaching",
    "Learn a subject from your favourite educator",
  ];

  const handleScroll = (id) => {
    const item = document.getElementById(id);

    if (item) {
      item.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className=" ">
      <div className="sticky top-0 z-50 bg-white">
        <Toolbar
          handleScroll={handleScroll}
          setIsDashBoardLogin={setIsDashBoardLogin}
        />
      </div>

      {showGlobalProgressIndicator === true ? (
        <DashBoardLoder />
      ) : (
        <div className="">
          <div className="flex justify-center">
            <Slider id="homeSlider" />
          </div>

          <div className="">
            <ActiveGoalTitle />
          </div>

          <div className="">
            <FeaturesContainer />
          </div>
          <div className="">
            <VideoSlider />
          </div>

          {/* <div className="">
            <ScholarTest />
          </div>

          <div className="flex justify-center">
            <EnableRating liveCompetitor={"976"} />
          </div> */}
          {/* <div className="flex justify-center">
            <TopEducatorSection id="educator" />
          </div> */}
          {/* <div className="flex justify-center">
            <SubscribeAdvertisment
              id="SubscribeAdvertisment"
              title={"Crack IIT JEE with our full-syllabus batches"}
              SubjectWise={SubjectWiseCourse}
            />
          </div> */}

          {/* <div className="flex justify-center">
            <CoursesShow />
          </div> */}

          {/* <div> 
            <CentersCase id="CentersCase" />
          </div> */}
          {/* <div className="flex justify-center">
            <ReferAfriend />
          </div> */}

          <div className="">
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashBoard;

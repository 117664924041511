import { useEffect, useState } from "react";
import Home from "./ UnacademyPages/UnacademyHome/Home";
import LoadingPage from "./ UnacademyPages/LoadingPage/LoadingPage";
import Login from "./ UnacademyPages/Login/Login";
import JoinFree from "./ UnacademyPages/JoinFree/JoinFree";

const UnacademyTheme = () => {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showJoinFreePopup, setShowJoinFreePopup] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setShowLoader(false);
      setShowLoginPopup(true);
    }, 2000);

    return () => clearTimeout(popupTimer);
  }, []);

  return (
    // <div className="flex justify-center items-center w-full h-screen bg-slate-800">
    //   <p className="text-white text-2xl">{WebConstant?.WEB_NAME} Home</p>
    // </div>

    <div>
      {/* <Home /> */}

      {showLoader ? (
        <LoadingPage />
      ) : (
        showLoginPopup && (
          <Login
            className="animate-wiggle"
            setShowLoginPopup={setShowLoginPopup}
            setShowJoinFreePopup={setShowJoinFreePopup}
          />
        )
      )}

      {showJoinFreePopup && (
        <JoinFree
          className="animate-wiggle"
          setShowJoinFreePopup={setShowJoinFreePopup}
          setShowLoginPopup={setShowLoginPopup}
        />
      )}
    </div>
  );
};

export default UnacademyTheme;

import React from "react";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import DarkFooter from "../Footer/DarkFooter";
import Footer from "../Footer/Footer";

function PrivacyPolicy() {
  const informationWeCollect = [
    {
      title:
        "Personal Information: When you register on Quest4Physics.com, we may collect personal information such as your name, email address, and contact details.",
    },
    {
      title:
        "Usage Data: We collect information about how you interact with our platform, including pages visited, time spent on each page, and other engagement metrics.",
    },
    {
      title:
        "Device Information: We may collect information about the device you use to access Quest4Physics.com, including device type, operating system, and browser type.",
    },
  ];
  const howWeUseYourInformation = [
    {
      title:
        "Personalization: We use the information collected to personalize your experience on Quest4Physics.com, including providing customized content and recommendations.",
    },
    {
      title:
        "Communication: We may use your contact information to send you updates, newsletters, and promotional materials related to our platform.",
    },
    {
      title:
        " Analytics: We analyze usage data to improve our platform, optimize user experience, and develop new features and services.",
    },
  ];
  const informationSharing = [
    {
      title:
        "Third-Party Service Providers: We may share your information with trusted third-party service providers who assist us in operating our platform, conducting business activities, or servicing you.",
    },
    {
      title:
        "Legal Compliance: We may disclose your information when required to comply with applicable laws, regulations, or legal processes.",
    },
    {
      title:
        "We reserve the right to change our fee structure and payment terms at any time. Such changes will be effective immediately upon posting on the Platform.",
    },
  ];
  const dataSecurity = [
    {
      title:
        "Encryption: We employ industry-standard security measures to protect your personal information from unauthorized access, misuse, or alteration.",
    },
    {
      title:
        "Access Controls: Access to your personal information is restricted to authorized personnel who need it to perform their duties.",
    },
  ];
  const yourRights = [
    {
      title:
        "Access and Correction: You have the right to access and update your personal information stored on Quest4Physics.com.",
    },
    {
      title:
        " Opt-Out: You can opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in our emails.",
    },
  ];

  return (
    <div className="">
      <Toolbar />

      <div className="flex justify-center flex-col  items-center  text-gray-700  ">
        <div className="w-[90%]  lg:w-[70%] tablet:w-[90%]  flex flex-col gap-10   tablet:py-3 items-start my-10 lg:px-10  ">
          <div className="flex justify-center  w-full text-2xl font-semibold  ">
            <p className="">Privacy Policy </p>
          </div>{" "}
          <div className="flex flex-col gap-5  w-full   ">
            <p className="font-medium border-y-2 py-5 text-sm md:text-base ">
              At Quest4Physics.com, safeguarding the privacy of our users is
              paramount. This Privacy Policy outlines how we collect, use, and
              protect your personal information when you use our educational
              technology platform.
            </p>
            <ul className="list-decimal px-5 flex flex-col gap-5">
              <li className="font-medium pb-5 text-sm md:text-base ">
                Information We Collect
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {informationWeCollect?.map((items, index) => (
                    <li key={index} className="">
                      <strong>{items.title.split(":")[0]}:</strong>{" "}
                      {items.title.split(":")[1]}
                    </li>
                  ))}
                </ul>
              </li>{" "}
              <li className="font-medium pb-5 text-sm md:text-base">
                How We Use Your Information
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {howWeUseYourInformation?.map((items, index) => (
                    <li key={index} className="">
                      <strong>{items.title.split(":")[0]}:</strong>{" "}
                      {items.title.split(":")[1]}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="font-medium pb-5 text-sm md:text-base">
                Information Sharing
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {informationSharing?.map((items, index) => (
                    <li key={index} className="">
                      <strong>{items.title.split(":")[0]}:</strong>{" "}
                      {items.title.split(":")[1]}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="font-medium pb-5 text-sm md:text-base">
                Data Security
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {dataSecurity?.map((items, index) => (
                    <li key={index} className="">
                      <strong>{items.title.split(":")[0]}:</strong>{" "}
                      {items.title.split(":")[1]}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="font-medium pb-5 text-sm md:text-base">
                Your Rights
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {yourRights?.map((items, index) => (
                    <li key={index} className="">
                      <strong>{items.title.split(":")[0]}:</strong>{" "}
                      {items.title.split(":")[1]}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="font-medium pb-5 text-sm md:text-base">
                Children's Privacy
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <p className=" px-5 mt-5 font-normal text-xs md:text-sm">
                  Quest4Physics.com is not intended for use by individuals under the
                  age of 13. We do not knowingly collect personal information
                  from children under 13 years of age. If you believe that we
                  have inadvertently collected personal information from a child
                  under 13, please contact us immediately, and we will take
                  appropriate steps to delete such information.
                </p>
              </li>{" "}
              <li className="font-medium pb-5 text-sm md:text-base">
                Changes to This Privacy Policy
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <p className=" px-5 mt-5 font-normal text-xs md:text-sm">
                  We reserve the right to update or modify this Privacy Policy
                  at any time. Any changes will be effective immediately upon
                  posting on this page.
                </p>
              </li>{" "}
              <li className="font-medium pb-5 text-sm md:text-base">
                Contact Us
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <p className=" px-5 mt-5 font-normal text-xs md:text-sm">
                  If you have any questions or concerns about our Privacy
                  Policy, please contact us at contact@Quest4Physics.com.
                </p>
              </li>{" "}
            </ul>
            <p className="font-medium border-y-2 py-5 text-sm md:text-base">
              By using Quest4Physics.com, you consent to the terms outlined in this
              Privacy Policy.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;

import React, { useRef, useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import prevIcon from "../../../assets/Dexlabz/prev-icon.png";
import nextIcon from "../../../assets/Dexlabz/next-icon.png";

import { ApiConstants } from "../../../api/ApiConstants";

function VideoSlider() {
  const ref = useRef();
  const videoRefs = useRef([]); // Array of video references
  const [activeIndex, setActiveIndex] = useState(0); // Active slide index

  const showNext = () => {
    ref.current.onClickNext();
  };

  const showPrevious = () => {
    ref.current.onClickPrev();
  };

  const videoSliderData = [
    { url: "Ayushivideo1.mp4" },
    { url: "AnilKumar.mp4" },
    { url: "Raj_Jaiswal.mp4" },
    { url: "manyaSinha.mp4" },
  ];

  // Handle video autoplay and pausing others
  useEffect(() => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        console.log("video ", activeIndex);

        if (index === activeIndex) {
          video.play(); // Play the current video
          // console.log("play ho gya")
        } else {
          video.pause(); // Pause all other videos
          // console.log("Pause ho gya");
        }
      }
    });
  }, [activeIndex]);

  const handleSlideChange = (index) => {
    setActiveIndex(index); // Update active index
  };

  console.log("img url");

  return (
    <div className="flex justify-center mt-10 relative">
      <div className="absolute flex w-[87%] tablet:w-[80%] lg:w-[78%] justify-between m-auto left-0 right-0 top-0 bottom-0 z-20 pointer-events-none">
        <button className="p-2 rounded-2xl" onClick={showPrevious}>
          <img
            src={prevIcon}
            alt="Previous"
            className="w-[50%] tablet:w-[75%] lg:w-[90%] pointer-events-auto"
          />
        </button>
        <button className="p-2 rounded-2xl" onClick={showNext}>
          <img
            src={nextIcon}
            alt="Next"
            className="w-[50%] tablet:w-[75%] lg:w-[90%] pointer-events-auto"
          />
        </button>
      </div>
      <Carousel
        ref={ref}
        centerMode={true}
        showThumbs={false}
        infiniteLoop={true}
        dynamicHeight={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        className="rounded-md"
        centerSlidePercentage={75}
        selectedItem={activeIndex}
        onChange={handleSlideChange} // Capture slide change to control videos
      >
        {videoSliderData.map((item, index) => (
          <div
            key={index}
            className="w-[100%] tablet:w-[90%] h-[7rem] tablet:h-[15rem] md:h-[30rem] px-2 pe-5 md:pe-0 lg:px-16 tablet:px-5 rounded-md z-30"
          >
            <video
              ref={(el) => (videoRefs.current[index] = el)} // Store ref for each video
              className="w-full h-full object-fill"
              // src={item?.url}
              src={`${ApiConstants?.cloudFront}video-assets/${item.url}`}
              controls
              muted
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default VideoSlider;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiConstants } from "../api/ApiConstants";
import { AuthContext } from "../providers/AuthProvider";
import { useContext } from "react";

const initialState = {
  userSignUpInfo: {},
  loading: false,
  error: false,
  errMsg: "",
};

export const LoginThunk = createAsyncThunk("user/register", (payload) => {
  const { accessToken } = useContext(AuthContext);

  return axios
    .post(
      ApiConstants.BASE_URL +
        "" +
        ApiConstants.UPDATE_USER_REGISTRATION_END_POINT,
      payload,
      {
        headers: {
          "auth-token": accessToken,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
});

const JoinFreeSlice = createSlice({
  name: "joinFreeReducer",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(LoginThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(LoginThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.userSignUpInfo = action.payload;
      state.error = "";
    });
    builder.addCase(LoginThunk.rejected, (state, action) => {
      state.loading = false;
      state.userSignUpInfo = {};
      state.error = action.error.message;
    });
  },
});

export default JoinFreeSlice.reducer;

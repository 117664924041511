import React, { useContext, useEffect } from "react";
import { optimizeImage } from "../../../api/ApiConstants";
import { useGetEnrolledByUserIdQuery } from "../../../api/ReduxToolkit";
import { AuthContext } from "../../../providers/AuthProvider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ClassroomCourseCard from "./ClassroomCourseCard";
import ButtonGroup from "../Shared/ButtonGroup/ButtonGroup";

function ChooseCourse({
  setCurrentCourseData,
  setShowSectionBox,
  showSectionBox,
}) {
  const { userInfo } = useContext(AuthContext);

  const { data: getMyEnrollments } = useGetEnrolledByUserIdQuery(userInfo?.uid);

  const handleCardClick = (courseData) => {
    // console.log("course data = ", courseData);
    setCurrentCourseData(courseData?.courseId);
    setShowSectionBox(!showSectionBox);
  };

  return (
    <div className="">
      <div className="text-xl mb-3">
        Choose <strong>Course</strong>
      </div>
      <div className="w-full lg:w-[90%] ">
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          autoPlaySpeed={3000}
          centerMode={false}
          className="w-full self-center z-0 "
          containerClass="container-with-dots "
          dotListClass={`text-xl`}
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 2,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1.9,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 3.2,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {getMyEnrollments?.data !== undefined &&
            getMyEnrollments?.data !== null &&
            getMyEnrollments?.data?.length > 0 &&
            getMyEnrollments?.data?.map((courseData, index) => (
              <ClassroomCourseCard
                key={index}
                courseTitle={courseData?.courseId?.courseName}
                courseImageUrl={optimizeImage(
                  courseData?.courseId?.courseThumbnail?.[0]?.resourceId,
                  300,
                  170,
                  60
                )}
                onClick={() => handleCardClick(courseData, index)}
              />
            ))}
        </Carousel>
      </div>
    </div>
  );
}

export default ChooseCourse;

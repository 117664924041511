import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { WebConstant } from "../../../../api/ApiConstants";

const EmailConformedPopup = ({ userInfo }) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const today = new Date().toDateString();
    const popupData = JSON.parse(localStorage.getItem("popupData")) || {};

    // Reset count if the date has changed
    if (popupData.date !== today) {
      popupData.date = today;
      popupData.count = 0;
    }

    // Show popup if it hasn't been shown twice today
    if (popupData.count < 2) {
      const popupTimer = setTimeout(() => {
        setShowPopup(true);
        popupData.count += 1;
        localStorage.setItem("popupData", JSON.stringify(popupData));
      }, 4000);

      // Clear timer on component unmount
      return () => clearTimeout(popupTimer);
    }
  }, []);

  return (
    <div
      className={`absolute right-[20%] z-50 bg-white text-gray-600 text-sm ms-auto flex flex-col justify-end rounded-xl shadow-xl px-5 py-5 w-[20rem] duration-700 border-t-4 border-orange-400 ${
        showPopup ? "top-20 hidden lg:block" : "-top-[500px]"
      }`}
    >
      <div className="flex justify-end text-2xl mb-5 absolute right-5 top-2">
        <AiOutlineClose
          onClick={() => setShowPopup(false)}
          className="cursor-pointer"
        />
      </div>

      <p className="text-base font-semibold">
        Confirm your email: <br /> {userInfo?.email}
      </p>

      <p className="my-5 text-sm">
        Check your inbox for {WebConstant?.WEB_NAME} confirmation email. A
        current email address helps ensure you don’t lose access to your
        account.
      </p>

      <p>
        <CustomButton
          lable={"Continue"}
          className={`bg-green-500 hover:bg-green-600 text-white border-none !mx-0`}
        />
      </p>
    </div>
  );
};

export default EmailConformedPopup;

import React, { useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { connect } from "react-redux";
import img1 from "../../../assets/Slider_Images/banner1.jpeg";
import img2 from "../../../assets/Slider_Images/banner2.jpeg";
import img3 from "../../../assets/Slider_Images/banner3.jpeg";
import img4 from "../../../assets/Slider_Images/banner4.jpeg";

import prevIcon from "../../../assets/Dexlabz/prev-icon.png";
import nextIcon from "../../../assets/Dexlabz/next-icon.png";

const Slider = ({ id, activeThumbnails }) => {
  const ref = useRef();
  const showNext = () => {
    ref.current.onClickNext();
  };

  const showPrevious = () => {
    ref.current.onClickPrev();
  };

  console.log("activeThumbnails", activeThumbnails);

  // Slider data with images and videos
  const sliderData = [
    {
      type: "image",
      url: img1,
    },
    {
      type: "image",
      url: img2,
    },
    {
      type: "image",
      url: img3,
    },
    {
      type: "image",
      url: img4,
    },
    // {
    //   type: "video",
    //   url: video1,
    // },
    // {
    //   type: "video",
    //   url: video2,
    // },
    // {
    //   type: "video",
    //   url: video3,
    // },
  ];

  return activeThumbnails?.length > 0 ? (
    <div
      className="flex items-center justify-center my-1 md:my-5 animate-blurIn"
      id={id}
    >
      <div className="absolute flex w-[87%] tablet:w-[80%] lg:w-[78%] justify-between mx-auto left-0 right-0 z-30">
        <button className="p-2 rounded-2xl" onClick={showPrevious}>
          <img
            src={prevIcon}
            alt="Previous"
            className="w-[50%] tablet:w-[75%] lg:w-[90%]"
          />
        </button>
        <button className="p-2 rounded-2xl" onClick={showNext}>
          <img
            src={nextIcon}
            alt="Next"
            className="w-[50%] tablet:w-[75%] lg:w-[90%]"
          />
        </button>
      </div>

      <Carousel
        ref={ref}
        centerMode={true}
        showThumbs={false}
        infiniteLoop={true}
        dynamicHeight={false}
        showStatus={false}
        showArrows={false}
        autoPlay={true}
        showIndicators={false}
        className="my-10"
        centerSlidePercentage={75}
      >
        {activeThumbnails.map(
          (item, index) => (
            // item.type === "image" ? (
            <img
              key={index}
              className=" md:rounded-xl w-[90%] tablet:w-[90%] h-[10rem] tablet:h-[15rem] md:h-[25rem] px-2 pe-5 md:pe-0 lg:px-16 tablet:px-5 "
              src={item.url}
              alt={`Slide ${index}`}
            />
          )
          // ) : (
          //   <div
          //     key={index}
          //     className=" w-[100%] tablet:w-[90%] h-[7rem] tablet:h-[15rem] md:h-[22rem] px-2 pe-5 md:pe-0 lg:px-16 tablet:px-5 rounded-md "
          //   >
          //     <video
          //       className="w-full h-full object-cover "
          //       src={item.url}
          //       controls
          //       autoPlay
          //       muted
          //     />
          //   </div>
          // )
        )}
      </Carousel>
    </div>
  ) : (
    <div></div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeThumbnails:
      state?.userSelectGoalReducer?.userGoalInfo?.activeGoal?.options
        ?.thumbnails,
  };
};

export default connect(mapStateToProps)(Slider);

import React, { useState, useRef } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { Document, Page, pdfjs } from "react-pdf";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
import { MdOutlineZoomIn, MdOutlineZoomOut } from "react-icons/md";
import PdfLoader from "./PdfLoader";
import { ApiConstants } from "../../../api/ApiConstants";

// Set the workerSrc to the location of the pdf worker file
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function PreviewPdf({
  bottomPopup,
  setShowLecturePdfPopup,
  currentLectureData,
}) {
  const pdfUrl =
    currentLectureData?.notes?.length > 0
      ? `${ApiConstants?.cloudFront}${
          currentLectureData?.notes?.[currentLectureData?.notes?.length - 1]
            ?.resourceId
        }`
      : null;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(0.3); // Adjusted default zoom level
  const [error, setError] = useState(null);

  const containerRef = useRef(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setError(null); // Reset error on successful load
  }

  function onDocumentLoadError(error) {
    console.error("Error loading document:", error);
    setError("Failed to load PDF file. Please try again later.");
  }

  // Zoom in and out functions
  const handleZoomIn = () => setScale((prevScale) => prevScale + 0.2);
  const handleZoomOut = () =>
    setScale((prevScale) => Math.max(prevScale - 0.2, 0.1)); // Minimum zoom scale set to 0.5

  // Handle page change functions
  const goToNextPage = () =>
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  const goToPreviousPage = () =>
    setPageNumber((prevPageNumber) => prevPageNumber - 1);

  console.log("currentLectureData", currentLectureData);

  return (
    <div className="fixed inset-0 bg-[rgba(128,128,128,.8)] flex justify-center items-center z-50">
      <div
        className={`bg-white w-full  h-[100vh] p-3 lg:p-10 shadow-lg relative  ${bottomPopup}`}
      >
        <IoCloseSharp
          className="absolute top-3 right-5 text-3xl cursor-pointer hover:text-gray-700 text-gray-600 z-30"
          onClick={() => setShowLecturePdfPopup(false)}
        />

        <div className="flex justify-center flex-col ">
          {error ? (
            <p className="text-red-600 pt-10">{error}</p>
          ) : pdfUrl ? (
            // Scrollable container
            <div
              className="h-[75vh] mt-10 flex justify-center overflow-auto w-full "
              ref={containerRef}
            >
              <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
                loading={<PdfLoader />}
              >
                <Page
                  pageNumber={pageNumber}
                  scale={scale} // Adjust zoom level dynamically
                />
              </Document>
            </div>
          ) : (
            <div className="w-full h-[70vh] flex flex-col justify-center items-center my-auto">
              <div className="flex justify-center">
                <img
                  src={require("../../../assets/Dexlabz/no_data_avalable.webp")}
                  alt="No data"
                  className="w-[50%] md:w-[15%]"
                />
              </div>
              <p className="text-lg text-gray-700 font-bold">
                No PDF available for this lecture.
              </p>
            </div>
          )}

          {numPages && (
            <div className="flex justify-between items-center mt-4 px-3">
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <div className="flex space-x-2 z-30">
                <button
                  disabled={pageNumber <= 1}
                  onClick={goToPreviousPage}
                  className="bg-gray-300 p-2 rounded disabled:opacity-50 duration-300 hover:scale-110 "
                >
                  <FaRegArrowAltCircleLeft className="size-5" />
                </button>
                <button
                  disabled={pageNumber >= numPages}
                  onClick={goToNextPage}
                  className="bg-gray-300 p-2 rounded disabled:opacity-50 hover:scale-110 duration-300"
                >
                  <FaRegArrowAltCircleRight className="size-5" />
                </button>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={handleZoomOut}
                  className="bg-gray-300 p-2 rounded disabled:opacity-50 hover:scale-110 duration-300"
                >
                  <MdOutlineZoomOut className="size-5" />
                </button>
                <button
                  onClick={handleZoomIn}
                  className="bg-gray-300 p-2 rounded disabled:opacity-50 hover:scale-110 duration-300"
                >
                  <MdOutlineZoomIn className="size-5" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PreviewPdf;

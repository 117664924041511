import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useGetLectureByLectureUidQuery } from "../../../api/ReduxToolkit";
import { ApiConstants } from "../../../api/ApiConstants";
import ReactHlsPlayer from "@ducanh2912/react-hls-player";
import PdfLoader from "../UcomPlayer/PdfLoader";

function FreeLecturesVideoPlayer({
  setShowVideoPlayer,
  bottomPopup,
  currentLectureData,
  courseData,
}) {
  const [videoPath, setVideoPath] = useState();

  // console.log("course Data", courseData);

  const { data: playerData, isLoading: playerDataIsLoading } =
    useGetLectureByLectureUidQuery(currentLectureData?.lectureUid, {
      skip: !currentLectureData?.lectureUid,
    });

  useEffect(() => {
    setVideoPath(
      playerData?.data?.detail?.outputGroupDetails?.[0]?.streamingUrl
    );
  }, [playerData]);

  return (
    <div className="bg-[rgba(128,128,128,.6)] w-full h-screen fixed top-0 left-0 z-50 flex items-center justify-center">
      {playerDataIsLoading && <PdfLoader />}
      <div
        className={`w-[95%] md:w-[70%]  bg-white    lg:h-[80vh]  relative rounded-2xl  transition-all duration-700  p-5 ${bottomPopup}`}
      >
        <div className="flex justify-between">
          <p className=" capitalize text-xl font-medium px-5">
            {currentLectureData?.lectureName}
          </p>
          <AiOutlineClose
            className="absolute text-3xl  top-3 right-3 cursor-pointer  p-1.5 rounded-full bg-gray-400 text-white hover:scale-105 duration-300  hover:rotate-90"
            onClick={() => setShowVideoPlayer(false)}
          />
        </div>
        <div className="mt-5 lg:mt-0 lg:p-5">
          {videoPath !== undefined && (
            <ReactHlsPlayer
              // onLoadedData={() => {
              //   const duration = location?.state?.duration;
              //   if (playerRef.current && duration) {
              //     playerRef.current.currentTime = duration;
              //   }
              // }}
              // playerRef={playerRef}
              autoPlay={false}
              src={`${ApiConstants?.cloudFront}${videoPath}`}
              controls={true}
              width="100%"
              className="rounded-lg shadow-sm "
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FreeLecturesVideoPlayer;

import React, { useEffect } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import BrandLogo from "../Shared/BrandLogo/BrandLogo";
import { WebConstant } from "../../../api/ApiConstants";

function MobileMenu({ setShowMenu, showMenu, bottomMenu }) {
  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showMenu]);

  const navigate = useNavigate();

  const handleDeleteToken = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <div
      className={`bg-[rgba(128,128,128,.2)] md:hidden tablet:block  h-screen  w-full flex justify-start items-start    absolute top-0 right-0     transition-all  z-50 ${
        showMenu ? "left-0 duration-200" : "-left-[5000px] duration-1000"
      }`}
    >
      <div
        className={`absolute z-10   w-[80%] bg-white  h-screen px-2  shadow-2xl  ${
          showMenu ? "left-0 duration-1000" : "-left-[500px] duration-500"
        }`}
      >
        <AiOutlineClose
          className="absolute text-base right-4 top-3 text-gray-500 rounded-full  "
          onClick={() => setShowMenu(false)}
        />
        <ul className="flex flex-col  w-full ">
          <li className="      py-2 my-1 md:hidden tablet:block block border-b flex flex-row  items-start ">
            {/* <BrandLogo className={` m-0  `} /> */}
            <p className="text-xl font-semibold ms-3 mt-3 text-gray-700">
              {WebConstant?.WEB_NAME}
            </p>
          </li>
          {bottomMenu.map((data, index) => (
            <li
              className="   hover:text-gray-600  px-3  py-2 my-1 hover:bg-slate-200 w-full rounded-lg"
              onClick={() => navigate(data.link)}
              key={index}
            >
              {data.item}
            </li>
          ))}

          <li
            className="   hover:text-gray-600  px-3  py-2 my-1 md:hidden tablet:block block hover:bg-slate-200  rounded-lg "
            onClick={() => navigate()}
          >
            Your Profile
          </li>
          <li
            className="   hover:text-gray-600   px-3  py-2 my-1 md:hidden tablet:block block hover:bg-slate-200 rounded-lg "
            onClick={() => handleDeleteToken()}
          >
            Log out
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MobileMenu;

import React from "react";

const SubStoreImageCard = ({
  imageUrl,
  backgroundColor,
  heading,
  language,
  config,
  contentBox,
  boxHeight,
  logoClassName,
}) => {
  return (
    <div
      className={`flex   flex-row  rounded-lg  w-full bg-gray-100 relative ${backgroundColor} ${config?.className}`}
    >
      <img
        src={require("../../../assets/Dexlabz/quest-short-logo.png")}
        alt=""
        className={` absolute left-0  opacity-50 scale-50 ${logoClassName}`}
      />
      {/* Left Description */}

      {/* Image in the Center */}
      <div className={`flex text-center justify-center z-30 w-full  `}>
        <img
          src={imageUrl}
          alt="Image"
          className={` align-right   rounded-lg object-cover  ${
            !boxHeight ? boxHeight : "h-[10rem]"
          }`}
        />
      </div>
    </div>
  );
};

export default SubStoreImageCard;

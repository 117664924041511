import React from "react";

const StoreImageCard = ({
  imageUrl,
  backgroundColor,
  heading,
  language,
  config,
}) => {
  return (
    <div
      className={`flex  justify-between flex-row  rounded-lg rounded-b-none  bg-gray-100 relative ${backgroundColor} ${config?.className}`}
    >
      <img
        src={require("../../../assets/Dexlabz/quest-short-logo.png")}
        alt=""
        className="absolute left-5 top-5  opacity-40 scale"
      />
      {/* Left Description */}
      {/* <div className="flex flex-col pt-5 ps-5  z-10 w-[50%] ">
        <h1 className="text-base  text-gray-800  font-extrabold mb-2 capitalize line-clamp-4">
          {heading}
        </h1>
        <p className="text-sm text-gray-600 font-medium">({language})</p>
      </div> */}

      {/* Image in the Center */}
      <div className="flex text-center justify-end z-10 w-full  ">
        <img
          src={imageUrl}
          alt="Image"
          className=" align-right h-[10rem]  rounded-lg object-cover "
        />
      </div>
    </div>
  );
};

export default StoreImageCard;

import React from "react";

const CourseImageCard = ({
  imageUrl,
  backgroundColor,
  heading,
  language,
  config,
  imageDirection,
  className,
  onClick,
}) => {
  return (
    <div
      className={`flex w-full justify-between   rounded-lg rounded-b-none  bg-gray-100 relative capitalize ${className} ${backgroundColor} ${
        config?.className
      } ${imageDirection === "left" ? "flex-row-reverse" : "flex-row"}`}
      onClick={onClick}
    >
      <img
        src={require("../../../assets/Dexlabz/quest-short-logo.png")}
        alt=""
        className=" absolute left-0 right-0 top-0 bottom-0 m-auto opacity-20 scale-90"
      />
      {/* Left Description */}
      <div
        className={`flex flex-col pt-5   z-10 w-[50%] ${
          imageDirection === "left" ? "pe-5" : "ps-5"
        }`}
      >
        <h1
          className={`text-base  text-gray-800  font-extrabold my-2 capitalize line-clamp-4
          ${imageDirection === "left" ? "text-end" : "text-start"}`}
        >
          {heading}
        </h1>
        {language && (
          <p className="text-sm text-gray-600 font-medium ">({language})</p>
        )}
      </div>

      {/* Image in the Center */}
      <div
        className={`flex text-center  z-10 w-[50%]  ${
          imageDirection === "left" ? "justify-start" : "justify-end"
        }`}
      >
        <img
          src={imageUrl}
          alt="Image"
          className="  align-right h-[10rem]  rounded-lg object-cover "
        />
      </div>

      {/* Right Description */}
      {/* <div className="flex-1 pl-4">
          <p className="text-lg text-gray-600">{descriptionRight}</p>
        </div> */}
    </div>
  );
};

export default CourseImageCard;
